// source: organizationmember.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.organization_member_endpoint.AccountSecurityInfo', null, global);
goog.exportSymbol('proto.organization_member_endpoint.AddUserReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.AddUserRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.AddUserResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.AddUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.DeleteUserReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.DeleteUserRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.DeleteUserResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.DeleteUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUserReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUserRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUserResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUsersReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUsersRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUsersResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.GetUsersResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.Profile', null, global);
goog.exportSymbol('proto.organization_member_endpoint.ProfileSummaryInfo', null, global);
goog.exportSymbol('proto.organization_member_endpoint.ResetUserPasswordReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.ResetUserPasswordRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.ResetUserPasswordResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.ResetUserPasswordResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.Role', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateAccountSecurityReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateAccountSecurityRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateAccountSecurityResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateAccountSecurityResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserActivityReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserActivityRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserActivityResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserConfirmationStateReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserConfirmationStateRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserConfirmationStateResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUsernameReplay', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUsernameRequest', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUsernameResponse', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UpdateUsernameResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UserSummaryInfo', null, global);
goog.exportSymbol('proto.organization_member_endpoint.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.AddUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.organization_member_endpoint.AddUserRequest.repeatedFields_, null);
};
goog.inherits(proto.organization_member_endpoint.AddUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.AddUserRequest.displayName = 'proto.organization_member_endpoint.AddUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.organization_member_endpoint.UpdateUserRequest.repeatedFields_, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserRequest.displayName = 'proto.organization_member_endpoint.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserActivityRequest.displayName = 'proto.organization_member_endpoint.UpdateUserActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserConfirmationStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.displayName = 'proto.organization_member_endpoint.UpdateUserConfirmationStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.DeleteUserRequest.displayName = 'proto.organization_member_endpoint.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.ResetUserPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.ResetUserPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.ResetUserPasswordRequest.displayName = 'proto.organization_member_endpoint.ResetUserPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateAccountSecurityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateAccountSecurityRequest.displayName = 'proto.organization_member_endpoint.UpdateAccountSecurityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUsernameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUsernameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUsernameRequest.displayName = 'proto.organization_member_endpoint.UpdateUsernameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.GetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.GetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.GetUsersRequest.displayName = 'proto.organization_member_endpoint.GetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.GetUserRequest.displayName = 'proto.organization_member_endpoint.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.AddUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.AddUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.AddUserReplay.displayName = 'proto.organization_member_endpoint.AddUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserReplay.displayName = 'proto.organization_member_endpoint.UpdateUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserActivityReplay.displayName = 'proto.organization_member_endpoint.UpdateUserActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserConfirmationStateReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.displayName = 'proto.organization_member_endpoint.UpdateUserConfirmationStateReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.ResetUserPasswordReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.ResetUserPasswordReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.ResetUserPasswordReplay.displayName = 'proto.organization_member_endpoint.ResetUserPasswordReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateAccountSecurityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateAccountSecurityReplay.displayName = 'proto.organization_member_endpoint.UpdateAccountSecurityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUsernameReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UpdateUsernameReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUsernameReplay.displayName = 'proto.organization_member_endpoint.UpdateUsernameReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.DeleteUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.DeleteUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.DeleteUserReplay.displayName = 'proto.organization_member_endpoint.DeleteUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.GetUsersReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.organization_member_endpoint.GetUsersReplay.repeatedFields_, null);
};
goog.inherits(proto.organization_member_endpoint.GetUsersReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.GetUsersReplay.displayName = 'proto.organization_member_endpoint.GetUsersReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.ProfileSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.ProfileSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.ProfileSummaryInfo.displayName = 'proto.organization_member_endpoint.ProfileSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UserSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UserSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UserSummaryInfo.displayName = 'proto.organization_member_endpoint.UserSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.Role.displayName = 'proto.organization_member_endpoint.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UserType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.UserType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UserType.displayName = 'proto.organization_member_endpoint.UserType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.organization_member_endpoint.Profile.repeatedFields_, null);
};
goog.inherits(proto.organization_member_endpoint.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.Profile.displayName = 'proto.organization_member_endpoint.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.GetUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.GetUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.GetUserReplay.displayName = 'proto.organization_member_endpoint.GetUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.AccountSecurityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_member_endpoint.AccountSecurityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.AccountSecurityInfo.displayName = 'proto.organization_member_endpoint.AccountSecurityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.AddUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.AddUserResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.AddUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.AddUserResponse.displayName = 'proto.organization_member_endpoint.AddUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.UpdateUserResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserResponse.displayName = 'proto.organization_member_endpoint.UpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.UpdateUserActivityResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserActivityResponse.displayName = 'proto.organization_member_endpoint.UpdateUserActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.UpdateUserConfirmationStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.displayName = 'proto.organization_member_endpoint.UpdateUserConfirmationStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.UpdateAccountSecurityResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.UpdateAccountSecurityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateAccountSecurityResponse.displayName = 'proto.organization_member_endpoint.UpdateAccountSecurityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.UpdateUsernameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.UpdateUsernameResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.UpdateUsernameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.UpdateUsernameResponse.displayName = 'proto.organization_member_endpoint.UpdateUsernameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.GetUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.GetUsersResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.GetUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.GetUsersResponse.displayName = 'proto.organization_member_endpoint.GetUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.GetUserResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.GetUserResponse.displayName = 'proto.organization_member_endpoint.GetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.ResetUserPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.ResetUserPasswordResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.ResetUserPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.ResetUserPasswordResponse.displayName = 'proto.organization_member_endpoint.ResetUserPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_member_endpoint.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_member_endpoint.DeleteUserResponse.oneofGroups_);
};
goog.inherits(proto.organization_member_endpoint.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_member_endpoint.DeleteUserResponse.displayName = 'proto.organization_member_endpoint.DeleteUserResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.organization_member_endpoint.AddUserRequest.repeatedFields_ = [4,5,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.AddUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.AddUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AddUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    username: jspb.Message.getFieldWithDefault(msg, 8, ""),
    password: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isaccountactive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isconfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    primaryemail: (f = msg.getPrimaryemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    primaryphonenumber: (f = msg.getPrimaryphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    organization: jspb.Message.getFieldWithDefault(msg, 14, 0),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.AddUserRequest}
 */
proto.organization_member_endpoint.AddUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.AddUserRequest;
  return proto.organization_member_endpoint.AddUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.AddUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.AddUserRequest}
 */
proto.organization_member_endpoint.AddUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountactive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsconfirmed(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryemail(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryphonenumber(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganization(value);
      break;
    case 15:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 16:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.AddUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.AddUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AddUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsaccountactive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsconfirmed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPrimaryemail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryphonenumber();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middleName = 2;
 * @return {string}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 4;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
*/
proto.organization_member_endpoint.AddUserRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 5;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
*/
proto.organization_member_endpoint.AddUserRequest.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional int32 userType = 6;
 * @return {number}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 role = 7;
 * @return {number}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string username = 8;
 * @return {string}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string password = 9;
 * @return {string}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool isAccountActive = 10;
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getIsaccountactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setIsaccountactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool isConfirmed = 11;
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getIsconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional google.protobuf.StringValue primaryEmail = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getPrimaryemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
*/
proto.organization_member_endpoint.AddUserRequest.prototype.setPrimaryemail = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.clearPrimaryemail = function() {
  return this.setPrimaryemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.hasPrimaryemail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue primaryPhoneNumber = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getPrimaryphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
*/
proto.organization_member_endpoint.AddUserRequest.prototype.setPrimaryphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.clearPrimaryphonenumber = function() {
  return this.setPrimaryphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.hasPrimaryphonenumber = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 organization = 14;
 * @return {number}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getOrganization = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional common.Address address = 15;
 * @return {?proto.common.Address}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 15));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
*/
proto.organization_member_endpoint.AddUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated common.ExtraField extraFields = 16;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 16));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
*/
proto.organization_member_endpoint.AddUserRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.organization_member_endpoint.AddUserRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.AddUserRequest} returns this
 */
proto.organization_member_endpoint.AddUserRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.organization_member_endpoint.UpdateUserRequest.repeatedFields_ = [4,5,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    primaryemail: (f = msg.getPrimaryemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    primaryphonenumber: (f = msg.getPrimaryphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    profileid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest}
 */
proto.organization_member_endpoint.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserRequest;
  return proto.organization_member_endpoint.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest}
 */
proto.organization_member_endpoint.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryemail(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryphonenumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 11:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 12:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPrimaryemail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryphonenumber();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middleName = 2;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 4;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
*/
proto.organization_member_endpoint.UpdateUserRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 5;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
*/
proto.organization_member_endpoint.UpdateUserRequest.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional int32 userType = 6;
 * @return {number}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 role = 7;
 * @return {number}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue primaryEmail = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getPrimaryemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
*/
proto.organization_member_endpoint.UpdateUserRequest.prototype.setPrimaryemail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.clearPrimaryemail = function() {
  return this.setPrimaryemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.hasPrimaryemail = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue primaryPhoneNumber = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getPrimaryphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
*/
proto.organization_member_endpoint.UpdateUserRequest.prototype.setPrimaryphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.clearPrimaryphonenumber = function() {
  return this.setPrimaryphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.hasPrimaryphonenumber = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string profileId = 10;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional common.Address address = 11;
 * @return {?proto.common.Address}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 11));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
*/
proto.organization_member_endpoint.UpdateUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated common.ExtraField extraFields = 12;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 12));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
*/
proto.organization_member_endpoint.UpdateUserRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.UpdateUserRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isaccountactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityRequest}
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserActivityRequest;
  return proto.organization_member_endpoint.UpdateUserActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityRequest}
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsaccountactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUserActivityRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAccountActive = 2;
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.prototype.getIsaccountactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_member_endpoint.UpdateUserActivityRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserActivityRequest.prototype.setIsaccountactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isaccountconfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserConfirmationStateRequest;
  return proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountconfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsaccountconfirmed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAccountConfirmed = 2;
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.prototype.getIsaccountconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateRequest} returns this
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateRequest.prototype.setIsaccountconfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.DeleteUserRequest}
 */
proto.organization_member_endpoint.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.DeleteUserRequest;
  return proto.organization_member_endpoint.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.DeleteUserRequest}
 */
proto.organization_member_endpoint.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.DeleteUserRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.DeleteUserRequest} returns this
 */
proto.organization_member_endpoint.DeleteUserRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.ResetUserPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.ResetUserPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordRequest}
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.ResetUserPasswordRequest;
  return proto.organization_member_endpoint.ResetUserPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.ResetUserPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordRequest}
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.ResetUserPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.ResetUserPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.ResetUserPasswordRequest} returns this
 */
proto.organization_member_endpoint.ResetUserPasswordRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateAccountSecurityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isemailtfenabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityRequest}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateAccountSecurityRequest;
  return proto.organization_member_endpoint.UpdateAccountSecurityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityRequest}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsemailtfenabled(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateAccountSecurityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsemailtfenabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isEmailTFEnabled = 2;
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.getIsemailtfenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue TFEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} returns this
*/
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateAccountSecurityRequest.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUsernameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUsernameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUsernameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUsernameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUsernameRequest}
 */
proto.organization_member_endpoint.UpdateUsernameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUsernameRequest;
  return proto.organization_member_endpoint.UpdateUsernameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUsernameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUsernameRequest}
 */
proto.organization_member_endpoint.UpdateUsernameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUsernameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUsernameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUsernameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUsernameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUsernameRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUsernameRequest} returns this
 */
proto.organization_member_endpoint.UpdateUsernameRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.organization_member_endpoint.UpdateUsernameRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.UpdateUsernameRequest} returns this
 */
proto.organization_member_endpoint.UpdateUsernameRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.GetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.GetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    role: (f = msg.getRole()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    usertype: (f = msg.getUsertype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.GetUsersRequest}
 */
proto.organization_member_endpoint.GetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.GetUsersRequest;
  return proto.organization_member_endpoint.GetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.GetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.GetUsersRequest}
 */
proto.organization_member_endpoint.GetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.GetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.GetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value role = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getRole = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value userType = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getUsertype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
*/
proto.organization_member_endpoint.GetUsersRequest.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersRequest} returns this
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersRequest.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.GetUserRequest}
 */
proto.organization_member_endpoint.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.GetUserRequest;
  return proto.organization_member_endpoint.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.GetUserRequest}
 */
proto.organization_member_endpoint.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_member_endpoint.GetUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.GetUserRequest} returns this
 */
proto.organization_member_endpoint.GetUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.AddUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.AddUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AddUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profileid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.AddUserReplay}
 */
proto.organization_member_endpoint.AddUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.AddUserReplay;
  return proto.organization_member_endpoint.AddUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.AddUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.AddUserReplay}
 */
proto.organization_member_endpoint.AddUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfileid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 5:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.AddUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.AddUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AddUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProfileid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string internalId = 1;
 * @return {string}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
 */
proto.organization_member_endpoint.AddUserReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 accountId = 2;
 * @return {number}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
 */
proto.organization_member_endpoint.AddUserReplay.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 profileId = 3;
 * @return {number}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.getProfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
 */
proto.organization_member_endpoint.AddUserReplay.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
*/
proto.organization_member_endpoint.AddUserReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
 */
proto.organization_member_endpoint.AddUserReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.MessageResponse message = 5;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 5));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
*/
proto.organization_member_endpoint.AddUserReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AddUserReplay} returns this
 */
proto.organization_member_endpoint.AddUserReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserReplay}
 */
proto.organization_member_endpoint.UpdateUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserReplay;
  return proto.organization_member_endpoint.UpdateUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserReplay}
 */
proto.organization_member_endpoint.UpdateUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserReplay} returns this
*/
proto.organization_member_endpoint.UpdateUserReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserReplay} returns this
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserReplay} returns this
*/
proto.organization_member_endpoint.UpdateUserReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserReplay} returns this
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityReplay}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserActivityReplay;
  return proto.organization_member_endpoint.UpdateUserActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityReplay}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserActivityReplay} returns this
*/
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityReplay} returns this
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserActivityReplay} returns this
*/
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityReplay} returns this
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserConfirmationStateReplay;
  return proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} returns this
*/
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} returns this
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} returns this
*/
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} returns this
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.ResetUserPasswordReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.ResetUserPasswordReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    newpassword: (f = msg.getNewpassword()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.ResetUserPasswordReplay;
  return proto.organization_member_endpoint.ResetUserPasswordReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.ResetUserPasswordReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNewpassword(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.ResetUserPasswordReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.ResetUserPasswordReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNewpassword();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay} returns this
*/
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay} returns this
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue newPassword = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.getNewpassword = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay} returns this
*/
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.setNewpassword = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay} returns this
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.clearNewpassword = function() {
  return this.setNewpassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.hasNewpassword = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay} returns this
*/
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordReplay} returns this
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ResetUserPasswordReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateAccountSecurityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityReplay}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateAccountSecurityReplay;
  return proto.organization_member_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityReplay}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} returns this
*/
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} returns this
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} returns this
*/
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityReplay} returns this
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateAccountSecurityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUsernameReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUsernameReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUsernameReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUsernameReplay}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUsernameReplay;
  return proto.organization_member_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUsernameReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUsernameReplay}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUsernameReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUsernameReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUsernameReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUsernameReplay} returns this
*/
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUsernameReplay} returns this
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUsernameReplay} returns this
*/
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUsernameReplay} returns this
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUsernameReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.DeleteUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.DeleteUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.DeleteUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.DeleteUserReplay}
 */
proto.organization_member_endpoint.DeleteUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.DeleteUserReplay;
  return proto.organization_member_endpoint.DeleteUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.DeleteUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.DeleteUserReplay}
 */
proto.organization_member_endpoint.DeleteUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.DeleteUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.DeleteUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.DeleteUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.DeleteUserReplay} returns this
*/
proto.organization_member_endpoint.DeleteUserReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.DeleteUserReplay} returns this
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_member_endpoint.DeleteUserReplay} returns this
*/
proto.organization_member_endpoint.DeleteUserReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.DeleteUserReplay} returns this
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.DeleteUserReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.organization_member_endpoint.GetUsersReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.GetUsersReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.GetUsersReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.GetUsersReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUsersReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.organization_member_endpoint.UserSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.GetUsersReplay}
 */
proto.organization_member_endpoint.GetUsersReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.GetUsersReplay;
  return proto.organization_member_endpoint.GetUsersReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.GetUsersReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.GetUsersReplay}
 */
proto.organization_member_endpoint.GetUsersReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.UserSummaryInfo;
      reader.readMessage(value,proto.organization_member_endpoint.UserSummaryInfo.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.GetUsersReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.GetUsersReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.GetUsersReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUsersReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.organization_member_endpoint.UserSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserSummaryInfo users = 1;
 * @return {!Array<!proto.organization_member_endpoint.UserSummaryInfo>}
 */
proto.organization_member_endpoint.GetUsersReplay.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.organization_member_endpoint.UserSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.organization_member_endpoint.UserSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.organization_member_endpoint.UserSummaryInfo>} value
 * @return {!proto.organization_member_endpoint.GetUsersReplay} returns this
*/
proto.organization_member_endpoint.GetUsersReplay.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.organization_member_endpoint.UserSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.organization_member_endpoint.UserSummaryInfo}
 */
proto.organization_member_endpoint.GetUsersReplay.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.organization_member_endpoint.UserSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.GetUsersReplay} returns this
 */
proto.organization_member_endpoint.GetUsersReplay.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.ProfileSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.ProfileSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ProfileSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    internalid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    usertype: (f = msg.getUsertype()) && proto.organization_member_endpoint.UserType.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.organization_member_endpoint.Role.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.ProfileSummaryInfo;
  return proto.organization_member_endpoint.ProfileSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.ProfileSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 8:
      var value = new proto.organization_member_endpoint.UserType;
      reader.readMessage(value,proto.organization_member_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 9:
      var value = new proto.organization_member_endpoint.Role;
      reader.readMessage(value,proto.organization_member_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.ProfileSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.ProfileSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ProfileSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.organization_member_endpoint.UserType.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.organization_member_endpoint.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse addedBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string internalId = 7;
 * @return {string}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional UserType userType = 8;
 * @return {?proto.organization_member_endpoint.UserType}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getUsertype = function() {
  return /** @type{?proto.organization_member_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UserType, 8));
};


/**
 * @param {?proto.organization_member_endpoint.UserType|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Role role = 9;
 * @return {?proto.organization_member_endpoint.Role}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.getRole = function() {
  return /** @type{?proto.organization_member_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.Role, 9));
};


/**
 * @param {?proto.organization_member_endpoint.Role|undefined} value
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
*/
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ProfileSummaryInfo} returns this
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ProfileSummaryInfo.prototype.hasRole = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UserSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UserSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UserSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.organization_member_endpoint.ProfileSummaryInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo}
 */
proto.organization_member_endpoint.UserSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UserSummaryInfo;
  return proto.organization_member_endpoint.UserSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UserSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo}
 */
proto.organization_member_endpoint.UserSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 8:
      var value = new proto.organization_member_endpoint.ProfileSummaryInfo;
      reader.readMessage(value,proto.organization_member_endpoint.ProfileSummaryInfo.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UserSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UserSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UserSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.organization_member_endpoint.ProfileSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ProfileSummaryInfo profile = 8;
 * @return {?proto.organization_member_endpoint.ProfileSummaryInfo}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.getProfile = function() {
  return /** @type{?proto.organization_member_endpoint.ProfileSummaryInfo} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.ProfileSummaryInfo, 8));
};


/**
 * @param {?proto.organization_member_endpoint.ProfileSummaryInfo|undefined} value
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
*/
proto.organization_member_endpoint.UserSummaryInfo.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserSummaryInfo} returns this
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserSummaryInfo.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.Role}
 */
proto.organization_member_endpoint.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.Role;
  return proto.organization_member_endpoint.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.Role}
 */
proto.organization_member_endpoint.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_member_endpoint.Role.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.Role} returns this
 */
proto.organization_member_endpoint.Role.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.Role.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.Role} returns this
*/
proto.organization_member_endpoint.Role.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Role} returns this
 */
proto.organization_member_endpoint.Role.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Role.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UserType.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UserType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UserType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UserType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UserType}
 */
proto.organization_member_endpoint.UserType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UserType;
  return proto.organization_member_endpoint.UserType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UserType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UserType}
 */
proto.organization_member_endpoint.UserType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UserType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UserType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UserType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UserType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_member_endpoint.UserType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.UserType} returns this
 */
proto.organization_member_endpoint.UserType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.UserType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.UserType} returns this
*/
proto.organization_member_endpoint.UserType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UserType} returns this
 */
proto.organization_member_endpoint.UserType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UserType.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.organization_member_endpoint.Profile.repeatedFields_ = [7,8,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: (f = msg.getUsertype()) && proto.organization_member_endpoint.UserType.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.organization_member_endpoint.Role.toObject(includeInstance, f),
    internalid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.Profile}
 */
proto.organization_member_endpoint.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.Profile;
  return proto.organization_member_endpoint.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.Profile}
 */
proto.organization_member_endpoint.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 9:
      var value = new proto.organization_member_endpoint.UserType;
      reader.readMessage(value,proto.organization_member_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 10:
      var value = new proto.organization_member_endpoint.Role;
      reader.readMessage(value,proto.organization_member_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 12:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 13:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.organization_member_endpoint.UserType.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.organization_member_endpoint.Role.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.organization_member_endpoint.Profile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.Profile.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.Profile.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.Profile.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse addedBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.organization_member_endpoint.Profile.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.Profile.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 7;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.organization_member_endpoint.Profile.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.Profile.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 8;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.organization_member_endpoint.Profile.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.Profile.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional UserType userType = 9;
 * @return {?proto.organization_member_endpoint.UserType}
 */
proto.organization_member_endpoint.Profile.prototype.getUsertype = function() {
  return /** @type{?proto.organization_member_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UserType, 9));
};


/**
 * @param {?proto.organization_member_endpoint.UserType|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Role role = 10;
 * @return {?proto.organization_member_endpoint.Role}
 */
proto.organization_member_endpoint.Profile.prototype.getRole = function() {
  return /** @type{?proto.organization_member_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.Role, 10));
};


/**
 * @param {?proto.organization_member_endpoint.Role|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasRole = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string internalId = 11;
 * @return {string}
 */
proto.organization_member_endpoint.Profile.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated common.ExtraField extraFields = 12;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.organization_member_endpoint.Profile.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 12));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.organization_member_endpoint.Profile.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.Address address = 13;
 * @return {?proto.common.Address}
 */
proto.organization_member_endpoint.Profile.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 13));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.organization_member_endpoint.Profile} returns this
*/
proto.organization_member_endpoint.Profile.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.Profile} returns this
 */
proto.organization_member_endpoint.Profile.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.Profile.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.GetUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.GetUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accountsecurity: (f = msg.getAccountsecurity()) && proto.organization_member_endpoint.AccountSecurityInfo.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.organization_member_endpoint.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.GetUserReplay}
 */
proto.organization_member_endpoint.GetUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.GetUserReplay;
  return proto.organization_member_endpoint.GetUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.GetUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.GetUserReplay}
 */
proto.organization_member_endpoint.GetUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 8:
      var value = new proto.organization_member_endpoint.AccountSecurityInfo;
      reader.readMessage(value,proto.organization_member_endpoint.AccountSecurityInfo.deserializeBinaryFromReader);
      msg.setAccountsecurity(value);
      break;
    case 9:
      var value = new proto.organization_member_endpoint.Profile;
      reader.readMessage(value,proto.organization_member_endpoint.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.GetUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.GetUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccountsecurity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.organization_member_endpoint.AccountSecurityInfo.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.organization_member_endpoint.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AccountSecurityInfo accountSecurity = 8;
 * @return {?proto.organization_member_endpoint.AccountSecurityInfo}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getAccountsecurity = function() {
  return /** @type{?proto.organization_member_endpoint.AccountSecurityInfo} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.AccountSecurityInfo, 8));
};


/**
 * @param {?proto.organization_member_endpoint.AccountSecurityInfo|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setAccountsecurity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearAccountsecurity = function() {
  return this.setAccountsecurity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasAccountsecurity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Profile profile = 9;
 * @return {?proto.organization_member_endpoint.Profile}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.getProfile = function() {
  return /** @type{?proto.organization_member_endpoint.Profile} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.Profile, 9));
};


/**
 * @param {?proto.organization_member_endpoint.Profile|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
*/
proto.organization_member_endpoint.GetUserReplay.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserReplay} returns this
 */
proto.organization_member_endpoint.GetUserReplay.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserReplay.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.AccountSecurityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.AccountSecurityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AccountSecurityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isemailtfenabled: (f = msg.getIsemailtfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isphonetfenabled: (f = msg.getIsphonetfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isauthapptfenabled: (f = msg.getIsauthapptfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tfphonenumber: (f = msg.getTfphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo}
 */
proto.organization_member_endpoint.AccountSecurityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.AccountSecurityInfo;
  return proto.organization_member_endpoint.AccountSecurityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.AccountSecurityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo}
 */
proto.organization_member_endpoint.AccountSecurityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsemailtfenabled(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsphonetfenabled(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsauthapptfenabled(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.AccountSecurityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.AccountSecurityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AccountSecurityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsemailtfenabled();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsphonetfenabled();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsauthapptfenabled();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTfphonenumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue isEmailTFEnabled = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.getIsemailtfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
*/
proto.organization_member_endpoint.AccountSecurityInfo.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.clearIsemailtfenabled = function() {
  return this.setIsemailtfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.hasIsemailtfenabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isPhoneTFEnabled = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.getIsphonetfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
*/
proto.organization_member_endpoint.AccountSecurityInfo.prototype.setIsphonetfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.clearIsphonetfenabled = function() {
  return this.setIsphonetfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.hasIsphonetfenabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAuthAppTFEnabled = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.getIsauthapptfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
*/
proto.organization_member_endpoint.AccountSecurityInfo.prototype.setIsauthapptfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.clearIsauthapptfenabled = function() {
  return this.setIsauthapptfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.hasIsauthapptfenabled = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue TFEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
*/
proto.organization_member_endpoint.AccountSecurityInfo.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue TFPhoneNumber = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.getTfphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
*/
proto.organization_member_endpoint.AccountSecurityInfo.prototype.setTfphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AccountSecurityInfo} returns this
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.clearTfphonenumber = function() {
  return this.setTfphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AccountSecurityInfo.prototype.hasTfphonenumber = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.AddUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.AddUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.AddUserResponse.ResultCase}
 */
proto.organization_member_endpoint.AddUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.AddUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.AddUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.AddUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.AddUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.AddUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AddUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.AddUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.AddUserResponse}
 */
proto.organization_member_endpoint.AddUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.AddUserResponse;
  return proto.organization_member_endpoint.AddUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.AddUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.AddUserResponse}
 */
proto.organization_member_endpoint.AddUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.AddUserReplay;
      reader.readMessage(value,proto.organization_member_endpoint.AddUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.AddUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.AddUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.AddUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.AddUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.AddUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddUserReplay success = 1;
 * @return {?proto.organization_member_endpoint.AddUserReplay}
 */
proto.organization_member_endpoint.AddUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.AddUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.AddUserReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.AddUserReplay|undefined} value
 * @return {!proto.organization_member_endpoint.AddUserResponse} returns this
*/
proto.organization_member_endpoint.AddUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.AddUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.AddUserResponse} returns this
 */
proto.organization_member_endpoint.AddUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.AddUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.UpdateUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.UpdateUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.UpdateUserResponse.ResultCase}
 */
proto.organization_member_endpoint.UpdateUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.UpdateUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.UpdateUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.UpdateUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserResponse}
 */
proto.organization_member_endpoint.UpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserResponse;
  return proto.organization_member_endpoint.UpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserResponse}
 */
proto.organization_member_endpoint.UpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.UpdateUserReplay;
      reader.readMessage(value,proto.organization_member_endpoint.UpdateUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.UpdateUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserReplay success = 1;
 * @return {?proto.organization_member_endpoint.UpdateUserReplay}
 */
proto.organization_member_endpoint.UpdateUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.UpdateUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UpdateUserReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.UpdateUserReplay|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserResponse} returns this
*/
proto.organization_member_endpoint.UpdateUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.UpdateUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserResponse} returns this
 */
proto.organization_member_endpoint.UpdateUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.UpdateUserActivityResponse.ResultCase}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.UpdateUserActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.UpdateUserActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.UpdateUserActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityResponse}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserActivityResponse;
  return proto.organization_member_endpoint.UpdateUserActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityResponse}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.UpdateUserActivityReplay;
      reader.readMessage(value,proto.organization_member_endpoint.UpdateUserActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.UpdateUserActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserActivityReplay success = 1;
 * @return {?proto.organization_member_endpoint.UpdateUserActivityReplay}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.UpdateUserActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UpdateUserActivityReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.UpdateUserActivityReplay|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserActivityResponse} returns this
*/
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.UpdateUserActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserActivityResponse} returns this
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.ResultCase}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUserConfirmationStateResponse;
  return proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.UpdateUserConfirmationStateReplay;
      reader.readMessage(value,proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.UpdateUserConfirmationStateReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserConfirmationStateReplay success = 1;
 * @return {?proto.organization_member_endpoint.UpdateUserConfirmationStateReplay}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.UpdateUserConfirmationStateReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UpdateUserConfirmationStateReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.UpdateUserConfirmationStateReplay|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse} returns this
*/
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUserConfirmationStateResponse} returns this
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUserConfirmationStateResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.UpdateAccountSecurityResponse.ResultCase}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.UpdateAccountSecurityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.UpdateAccountSecurityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateAccountSecurityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.UpdateAccountSecurityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityResponse}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateAccountSecurityResponse;
  return proto.organization_member_endpoint.UpdateAccountSecurityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityResponse}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.UpdateAccountSecurityReplay;
      reader.readMessage(value,proto.organization_member_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateAccountSecurityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateAccountSecurityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountSecurityReplay success = 1;
 * @return {?proto.organization_member_endpoint.UpdateAccountSecurityReplay}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.UpdateAccountSecurityReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UpdateAccountSecurityReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.UpdateAccountSecurityReplay|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityResponse} returns this
*/
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.UpdateAccountSecurityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateAccountSecurityResponse} returns this
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateAccountSecurityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.UpdateUsernameResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.UpdateUsernameResponse.ResultCase}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.UpdateUsernameResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.UpdateUsernameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.UpdateUsernameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.UpdateUsernameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUsernameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.UpdateUsernameReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.UpdateUsernameResponse}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.UpdateUsernameResponse;
  return proto.organization_member_endpoint.UpdateUsernameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.UpdateUsernameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.UpdateUsernameResponse}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.UpdateUsernameReplay;
      reader.readMessage(value,proto.organization_member_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.UpdateUsernameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.UpdateUsernameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.UpdateUsernameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.UpdateUsernameReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUsernameReplay success = 1;
 * @return {?proto.organization_member_endpoint.UpdateUsernameReplay}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.UpdateUsernameReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.UpdateUsernameReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.UpdateUsernameReplay|undefined} value
 * @return {!proto.organization_member_endpoint.UpdateUsernameResponse} returns this
*/
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.UpdateUsernameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.UpdateUsernameResponse} returns this
 */
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.UpdateUsernameResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.GetUsersResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.GetUsersResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.GetUsersResponse.ResultCase}
 */
proto.organization_member_endpoint.GetUsersResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.GetUsersResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.GetUsersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.GetUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.GetUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.GetUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.GetUsersReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.GetUsersResponse}
 */
proto.organization_member_endpoint.GetUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.GetUsersResponse;
  return proto.organization_member_endpoint.GetUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.GetUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.GetUsersResponse}
 */
proto.organization_member_endpoint.GetUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.GetUsersReplay;
      reader.readMessage(value,proto.organization_member_endpoint.GetUsersReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.GetUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.GetUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.GetUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.GetUsersReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetUsersReplay success = 1;
 * @return {?proto.organization_member_endpoint.GetUsersReplay}
 */
proto.organization_member_endpoint.GetUsersResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.GetUsersReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.GetUsersReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.GetUsersReplay|undefined} value
 * @return {!proto.organization_member_endpoint.GetUsersResponse} returns this
*/
proto.organization_member_endpoint.GetUsersResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.GetUsersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUsersResponse} returns this
 */
proto.organization_member_endpoint.GetUsersResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUsersResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.GetUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.GetUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.GetUserResponse.ResultCase}
 */
proto.organization_member_endpoint.GetUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.GetUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.GetUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.GetUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.GetUserResponse}
 */
proto.organization_member_endpoint.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.GetUserResponse;
  return proto.organization_member_endpoint.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.GetUserResponse}
 */
proto.organization_member_endpoint.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.GetUserReplay;
      reader.readMessage(value,proto.organization_member_endpoint.GetUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.GetUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetUserReplay success = 1;
 * @return {?proto.organization_member_endpoint.GetUserReplay}
 */
proto.organization_member_endpoint.GetUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.GetUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.GetUserReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.GetUserReplay|undefined} value
 * @return {!proto.organization_member_endpoint.GetUserResponse} returns this
*/
proto.organization_member_endpoint.GetUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.GetUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.GetUserResponse} returns this
 */
proto.organization_member_endpoint.GetUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.GetUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.ResetUserPasswordResponse.ResultCase}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.ResetUserPasswordResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.ResetUserPasswordResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.ResetUserPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.ResetUserPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.ResetUserPasswordReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordResponse}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.ResetUserPasswordResponse;
  return proto.organization_member_endpoint.ResetUserPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.ResetUserPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordResponse}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.ResetUserPasswordReplay;
      reader.readMessage(value,proto.organization_member_endpoint.ResetUserPasswordReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.ResetUserPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.ResetUserPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.ResetUserPasswordReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResetUserPasswordReplay success = 1;
 * @return {?proto.organization_member_endpoint.ResetUserPasswordReplay}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.ResetUserPasswordReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.ResetUserPasswordReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.ResetUserPasswordReplay|undefined} value
 * @return {!proto.organization_member_endpoint.ResetUserPasswordResponse} returns this
*/
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.ResetUserPasswordResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.ResetUserPasswordResponse} returns this
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.ResetUserPasswordResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_member_endpoint.DeleteUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_member_endpoint.DeleteUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_member_endpoint.DeleteUserResponse.ResultCase}
 */
proto.organization_member_endpoint.DeleteUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_member_endpoint.DeleteUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_member_endpoint.DeleteUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_member_endpoint.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_member_endpoint.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_member_endpoint.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_member_endpoint.DeleteUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_member_endpoint.DeleteUserResponse}
 */
proto.organization_member_endpoint.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_member_endpoint.DeleteUserResponse;
  return proto.organization_member_endpoint.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_member_endpoint.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_member_endpoint.DeleteUserResponse}
 */
proto.organization_member_endpoint.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_member_endpoint.DeleteUserReplay;
      reader.readMessage(value,proto.organization_member_endpoint.DeleteUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_member_endpoint.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_member_endpoint.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_member_endpoint.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_member_endpoint.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_member_endpoint.DeleteUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteUserReplay success = 1;
 * @return {?proto.organization_member_endpoint.DeleteUserReplay}
 */
proto.organization_member_endpoint.DeleteUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_member_endpoint.DeleteUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_member_endpoint.DeleteUserReplay, 1));
};


/**
 * @param {?proto.organization_member_endpoint.DeleteUserReplay|undefined} value
 * @return {!proto.organization_member_endpoint.DeleteUserResponse} returns this
*/
proto.organization_member_endpoint.DeleteUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_member_endpoint.DeleteUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_member_endpoint.DeleteUserResponse} returns this
 */
proto.organization_member_endpoint.DeleteUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_member_endpoint.DeleteUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.organization_member_endpoint);
