import * as Yup from 'yup';
import i18next from './i18n';
export const inputs = {

    password: Yup.string()

        .max(40, i18next.t("passwordValidation"))
        .min(6, i18next.t("passwordValidation"))
        .required(i18next.t("required")),

    roleName: Yup.string()
        .max(50, i18next.t("roleNameValidation"))
        .min(1, i18next.t("roleNameValidation"))
        .required(i18next.t("required")),
    userTypeName: Yup.string()
        .max(50, i18next.t("userTypeNameValidation"))
        .min(1, i18next.t("userTypeNameValidation"))
        .required(i18next.t("required")),
    groupName: Yup.string()
        .max(50, i18next.t("groupNameValidation"))
        .min(1, i18next.t("groupNameValidation"))
        .required(i18next.t("required")),
    groupDescription: Yup.string()
        .max(140, i18next.t("groupDescriptionValidation")),
    organizationName: Yup.string()
        .min(1, i18next.t("organizationNameValidation"))
        .max(150, i18next.t("organizationNameValidation"))
        .required(i18next.t("required")),
    organizationDomain: Yup.string().email(i18next.t("organizationDomainEmailValidation"))
        .min(1, i18next.t("organizationDomainValidation"))
        .max(250, i18next.t("organizationDomainValidation"))
        .required(i18next.t("required")),
    organizationAddress: Yup.string()
        .max(500, i18next.t("organizationAddressValidation")),


    firstname: Yup.string()
        .max(50, i18next.t("firstnameValidation"))
        .min(1, i18next.t("firstnameValidation"))
        .required(i18next.t("required")),
    lastname: Yup.string()
        .max(50, i18next.t("lastnameValidation"))
        .min(1, i18next.t("lastnameValidation"))
        .required(i18next.t("required")),

    username: Yup.string()
        .max(320, i18next.t("usernameValidation"))
        .min(3, i18next.t("usernameValidation"))
        .required(i18next.t("required")),
    customerUsername: Yup.string().email(i18next.t("usernameEmailValidation"))
        .max(320, i18next.t("usernameValidation"))
        .min(3, i18next.t("usernameValidation"))
        .required(i18next.t("required")),

    code: Yup.number()
        .max(999999, i18next.t("codeValidation"))
        .min(0, i18next.t("codeValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),


    phoneNumbers: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().matches(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/, i18next.t("phoneNumberValidation")).required(i18next.t("required")), // these constraints take precedence
        })
    ),

    emailAddresses: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().email(i18next.t("emailValidation")).required('Required'), // these constraints take precedence
        })
    ),

    newPassword: Yup.string()
        .max(40, i18next.t("passwordValidation"))
        .min(6, i18next.t("passwordValidation"))
        .required(i18next.t("required")),
    rePassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], i18next.t("rePasswordValidation"))
        .required(i18next.t("required")),


    primaryPhoneNumber: Yup.string().matches(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/, i18next.t("phoneNumberValidation")),

    primaryEmail: Yup.string().email(i18next.t("emailValidation")),

    extraFields: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .max(50, i18next.t("extraFieldsValidation"))
                .min(1, i18next.t("extraFieldsValidation"))
                .required(i18next.t("required")),
            label: Yup.string()
                .max(50, i18next.t("extraFieldsValidation"))
                .min(1, i18next.t("extraFieldsValidation"))
                .required(i18next.t("required")),
        })
    ),

    enums: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .max(500, i18next.t("enumsValidation"))
                .min(1, i18next.t("enumsValidation"))
                .required(i18next.t("required")), // these constraints take precedence
        })
    ),
    blocked: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .max(500, i18next.t("blockedValidation"))
                .min(1, i18next.t("blockedValidation"))
                .required(i18next.t("required")), // these constraints take precedence
        })
    ),

    range: Yup.array().of(
        Yup.object().shape({
            label: Yup.string()
                .max(200, i18next.t("rangeValidation"))
                .min(1, i18next.t("rangeValidation"))
                .required(i18next.t("required")),
            value: Yup.string()
                .max(200, i18next.t("rangeValidation"))
                .min(1, i18next.t("rangeValidation"))
                .required(i18next.t("required")),
        })
    ),

    regex: Yup.string()
        .max(1000, i18next.t("regexValidation"))
        .min(1, i18next.t("regexValidation")),



    note: Yup.string()
        .max(140, i18next.t("noteValidation")),

    TwoFactorPhoneNumber: Yup.string().matches(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/, i18next.t("phoneNumberValidation")).required(i18next.t("required")),

    TwoFactorEmail: Yup.string().email(i18next.t("emailValidation")).required(i18next.t("required")),

}



