
export interface Page {
    key: string,
    title: string,
    path: string,
    parentKey?: string,
    nav: string
}

const appPages: Page[] = [
    {
        key: "dashboard",
        title: "dashboard",
        path: "",
        nav: "dashboard"
        //parentKey: "users"
    },
    {
        key: "organizations",
        title: "organizations",
        path: "organizations",
        nav: "organizations"
        //parentKey: "users"

    },

    {
        key: "login",
        title: "sarafa",
        path: "login",
        nav: ""
        //parentKey: "users"
    },
    {
        key: "logs",
        title: "logs",
        path: "logs",
        nav: "logs"
        //parentKey: "users"
    },
    {
        key: "logs/details",
        title: "logDetails",
        path: "logs/:id",
        parentKey: "logs",
        nav: "logs"
    },

    {
        key: "clients/logs",
        title: "logs",
        path: "clients/logs",
        nav: "clients/logs"
        //parentKey: "users"
    },
    {
        key: "clients/logs/details",
        title: "logDetails",
        path: "clients/logs/:id",
        parentKey: "clients/logs",
        nav: "clients/logs"
    },


    {
        key: "profile",
        title: "myProfilePage",
        path: "profile",
        nav: ""
        //parentKey: "users"
    },

    {
        key: "organizations/details",
        title: "organizationDetails",
        path: "organizations/:id",
        parentKey: "organizations",
        nav: "organizations"
    },


    {
        key: "management",
        title: "management",
        path: "management/users",
        nav: "management"

    },
    {
        key: "management/users",
        title: "users",
        path: "management/users",
        parentKey: "management",
        nav: "management/users"

    },
    {
        key: "management/users/add",
        title: "addManager",
        path: "management/users/add",
        parentKey: "management/users",
        nav: "management/users"

    },
    {
        key: "management/users/details",
        title: "managerDetails",
        path: "management/users/:id",
        parentKey: "management/users",
        nav: "management/users"
    },
    {
        key: "management/usersTypes",
        title: "usersTypes",
        path: "management/user-types",
        parentKey: "management",
        nav: "management/usersTypes"


    },
    {
        key: "management/usersTypes/details",
        title: "userTypeDetails",
        path: "management/user-types/:id",
        parentKey: "management/usersTypes",
        nav: "management/usersTypes"
    },

    {
        key: "management/privileges",
        title: "privileges",
        path: "management/privileges",
        parentKey: "management",
        nav: "management/privileges"


    },

   

    
    {
        key: "management/privileges/details",
        title: "privilegeDetails",
        path: "management/privileges/:id",
        parentKey: "management/privileges",
        nav: "management/privileges"
    },
    {
        key: "management/privilege-data/details",
        title: "privilegeDataDetails",
        path: "management/privilege-data/:id",
        nav: "management/privileges",
        parentKey: "management",

    },
    {
        key: "management/roles",
        title: "roles",
        path: "management/roles",
        parentKey: "management",
        nav: "management/roles"


    },
    {
        key: "management/roles/details",
        title: "roleDetails",
        path: "management/roles/:id",
        parentKey: "management/roles",
        nav: "management/roles"
    },
    {
        key: "management/groups",
        title: "groups",
        path: "management/groups",
        parentKey: "management",
        nav: "management/groups"


    },
    {
        key: "management/groups/details",
        title: "groupDetails",
        path: "management/groups/:id",
        parentKey: "management/groups",
        nav: "management/groups"
    },

    {
        key: "management/groupMembership/details",
        title: "groupMembershipDetails",
        path: "management/group-memberships/:id",
        nav: "management/groups"
    },


    {
        key: "management/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "management/data-restrictions/:id",
        nav: "management/privileges"
    },




    {
        key: "management/rolePrivilege/details",
        title: "rolePrivilegeDetails",
        path: "management/role-privileges/:id",
        nav: "management/roles"
    },

    {
        key: "customers",
        title:"management",
        path: "customers/users",
        nav: "customers"

    },
    {
        key: "customers/users",
        title: "users",
        path: "customers/users",
        parentKey: "customers",
        nav: "customers/users"

    },
    {
        key: "customers/users/add",
        title: "addCustomer",
        path: "customers/users/add",
        parentKey: "customers/users",
        nav: "customers/users"

    },
    {
        key: "customers/users/details",
        title: "managerDetails",
        path: "customers/users/:id",
        parentKey: "customers/users",
        nav: "customers/users"
    },
    {
        key: "customers/usersTypes",
        title: "usersTypes",
        path: "customers/user-types",
        parentKey: "customers",
        nav: "customers/usersTypes"


    },
    {
        key: "customers/usersTypes/details",
        title: "userTypeDetails",
        path: "customers/user-types/:id",
        parentKey: "customers/usersTypes",
        nav: "customers/usersTypes"
    },

    {
        key: "customers/privileges",
        title: "privileges",
        path: "customers/privileges",
        parentKey: "customers",
        nav: "customers/privileges"


    },
    {
        key: "customers/privileges/details",
        title: "privilegeDetails",
        path: "customers/privileges/:id",
        parentKey: "customers/privileges",
        nav: "customers/privileges"
    },
    {
        key: "customers/privilege-data/details",
        title: "privilegeDataDetails",
        path: "customers/privilege-data/:id",
        nav: "customers/privileges",
        parentKey: "customers",
    },
    {
        key: "customers/roles",
        title: "roles",
        path: "customers/roles",
        parentKey: "customers",
        nav: "customers/roles"


    },
    {
        key: "customers/roles/details",
        title: "roleDetails",
        path: "customers/roles/:id",
        parentKey: "customers/roles",
        nav: "customers/roles"
    },
    {
        key: "customers/groups",
        title: "groups",
        path: "customers/groups",
        parentKey: "customers",
        nav: "customers/groups"


    },
    {
        key: "customers/groups/details",
        title: "groupDetails",
        path: "customers/groups/:id",
        parentKey: "customers/groups",
        nav: "customers/groups"
    },

    {
        key: "customers/groupMembership/details",
        title: "groupMembershipDetails",
        path: "customers/group-memberships/:id",
        nav: "customers/groups"
    },


    {
        key: "customers/groupMembership/details",
        title: "groupMembershipDetails",
        path: "customers/group-memberships/:id",
        nav: "customers/groups"
    },


    {
        key: "customers/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "customers/data-restrictions/:id",
        nav: "customers/privileges"
    },
    {
        key: "customers/rolePrivilege/details",
        title: "subscriptionServiceDetails",
        path: "customers/role-privileges/:id",
        nav: "customers/roles"
    },





    {
        key: "clients/groupMembership/details",
        title: "groupMembershipDetails",
        path: "clients/group-memberships/:id",
        nav: "clients/groups"
    },


    {
        key: "clients/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "clients/data-restrictions/:id",
        nav: "clients/privileges"
    },

   {
        key: "clients/rolePrivilege/details",
        title: "subscriptionServiceDetails",
        path: "clients/role-privileges/:id",
        nav: "clients/roles"
    },

 {
        key: "clients",
        title: "clients",
        path: "clients/users",
        nav: "clients"

    },
    {
        key: "clients/users",
        title: "profiles",
        path: "clients/users",
        parentKey: "clients",
        nav: "clients/users"

    },
    {
        key: "clients/users/add",
        title: "addCustomer",
        path: "clients/users/add",
        parentKey: "clients/users",
        nav: "clients/users"

    },
    {
        key: "clients/users/details",
        title: "customerDetails",
        path: "clients/users/:id",
        parentKey: "clients/users",
        nav: "clients/users"
    },
    {
        key: "clients/usersTypes",
        title: "clientTypes",
        path: "clients/user-types",
        parentKey: "clients",
        nav: "clients/usersTypes"


    },
    {
        key: "clients/usersTypes/details",
        title: "clientTypeDetails",
        path: "clients/user-types/:id",
        parentKey: "clients/usersTypes",
        nav: "clients/usersTypes"
    },

    {
        key: "clients/privileges",
        title: "services",
        path: "clients/privileges",
        parentKey: "clients",
        nav: "clients/privileges"


    },
    {
        key: "clients/privileges/details",
        title: "serviceDetails",
        path: "clients/privileges/:id",
        parentKey: "clients/privileges",
        nav: "clients/privileges"
    },
    {
        key: "clients/privilege-data/details",
        title: "serviceDataDetails",
        path: "clients/privilege-data/:id",
        nav: "clients/privileges",
        parentKey: "clients",
    },
    {
        key: "clients/roles",
        title: "subscriptions",
        path: "clients/roles",
        parentKey: "clients",
        nav: "clients/roles"


    },
    {
        key: "clients/roles/details",
        title: "subscriptionDetails",
        path: "clients/roles/:id",
        parentKey: "clients/roles",
        nav: "clients/roles"
    },
    {
        key: "clients/groups",
        title: "groups",
        path: "clients/groups",
        parentKey: "clients",
        nav: "clients/groups"


    },
    {
        key: "clients/groups/details",
        title: "groupDetails",
        path: "clients/groups/:id",
        parentKey: "clients/groups",
        nav: "clients/groups"
    },

    {
        key: "clients/groupMembership/details",
        title: "groupMembershipDetails",
        path: "clients/group-memberships/:id",
        nav: "clients/groups"
    },














];


export const getPage = (key: string) => appPages.filter(e => e.key == key)[0];

