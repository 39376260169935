// source: client.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.clients_user_endpoint.AccessToken', null, global);
goog.exportSymbol('proto.clients_user_endpoint.AccountSecurityInfo', null, global);
goog.exportSymbol('proto.clients_user_endpoint.AddUserReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.AddUserRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.AddUserResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.AddUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.DeleteCustomerReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.DeleteCustomerRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.DeleteCustomerResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.DeleteCustomerResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GenerateAccessTokenReply', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GenerateAccessTokenRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GenerateAccessTokenResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GenerateAccessTokenResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUserByUsernameRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUserReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUserRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUserResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUsersReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUsersRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUsersResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.GetUsersResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.Organization', null, global);
goog.exportSymbol('proto.clients_user_endpoint.Profile', null, global);
goog.exportSymbol('proto.clients_user_endpoint.ProfileSummaryInfo', null, global);
goog.exportSymbol('proto.clients_user_endpoint.ResetUserPasswordReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.ResetUserPasswordRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.ResetUserPasswordResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.ResetUserPasswordResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.Role', null, global);
goog.exportSymbol('proto.clients_user_endpoint.SyncInformationReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.SyncInformationRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.SyncInformationResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.SyncInformationResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateAccountSecurityReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateAccountSecurityRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateAccountSecurityResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateAccountSecurityResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateProtectionReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateProtectionRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateProtectionResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateProtectionResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserActivityReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserActivityRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserActivityResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserConfirmationStateReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserConfirmationStateRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserConfirmationStateResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUserResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUsernameReplay', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUsernameRequest', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUsernameResponse', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UpdateUsernameResponse.ResultCase', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UserSummaryInfo', null, global);
goog.exportSymbol('proto.clients_user_endpoint.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.GenerateAccessTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GenerateAccessTokenRequest.displayName = 'proto.clients_user_endpoint.GenerateAccessTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.AddUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_user_endpoint.AddUserRequest.repeatedFields_, null);
};
goog.inherits(proto.clients_user_endpoint.AddUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.AddUserRequest.displayName = 'proto.clients_user_endpoint.AddUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.SyncInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_user_endpoint.SyncInformationRequest.repeatedFields_, null);
};
goog.inherits(proto.clients_user_endpoint.SyncInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.SyncInformationRequest.displayName = 'proto.clients_user_endpoint.SyncInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_user_endpoint.UpdateUserRequest.repeatedFields_, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserRequest.displayName = 'proto.clients_user_endpoint.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserActivityRequest.displayName = 'proto.clients_user_endpoint.UpdateUserActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.DeleteCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.DeleteCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.DeleteCustomerRequest.displayName = 'proto.clients_user_endpoint.DeleteCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateProtectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateProtectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateProtectionRequest.displayName = 'proto.clients_user_endpoint.UpdateProtectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserConfirmationStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.displayName = 'proto.clients_user_endpoint.UpdateUserConfirmationStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.ResetUserPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.ResetUserPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.ResetUserPasswordRequest.displayName = 'proto.clients_user_endpoint.ResetUserPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUserByUsernameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.GetUserByUsernameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUserByUsernameRequest.displayName = 'proto.clients_user_endpoint.GetUserByUsernameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateAccountSecurityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateAccountSecurityRequest.displayName = 'proto.clients_user_endpoint.UpdateAccountSecurityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUsernameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUsernameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUsernameRequest.displayName = 'proto.clients_user_endpoint.UpdateUsernameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.GetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUsersRequest.displayName = 'proto.clients_user_endpoint.GetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUserRequest.displayName = 'proto.clients_user_endpoint.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.Organization.displayName = 'proto.clients_user_endpoint.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.AccessToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.AccessToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.AccessToken.displayName = 'proto.clients_user_endpoint.AccessToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GenerateAccessTokenReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.GenerateAccessTokenReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GenerateAccessTokenReply.displayName = 'proto.clients_user_endpoint.GenerateAccessTokenReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.AddUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.AddUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.AddUserReplay.displayName = 'proto.clients_user_endpoint.AddUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserReplay.displayName = 'proto.clients_user_endpoint.UpdateUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.DeleteCustomerReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.DeleteCustomerReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.DeleteCustomerReplay.displayName = 'proto.clients_user_endpoint.DeleteCustomerReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.SyncInformationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.SyncInformationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.SyncInformationReplay.displayName = 'proto.clients_user_endpoint.SyncInformationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserActivityReplay.displayName = 'proto.clients_user_endpoint.UpdateUserActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserConfirmationStateReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.displayName = 'proto.clients_user_endpoint.UpdateUserConfirmationStateReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateProtectionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateProtectionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateProtectionReplay.displayName = 'proto.clients_user_endpoint.UpdateProtectionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.ResetUserPasswordReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.ResetUserPasswordReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.ResetUserPasswordReplay.displayName = 'proto.clients_user_endpoint.ResetUserPasswordReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateAccountSecurityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateAccountSecurityReplay.displayName = 'proto.clients_user_endpoint.UpdateAccountSecurityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUsernameReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UpdateUsernameReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUsernameReplay.displayName = 'proto.clients_user_endpoint.UpdateUsernameReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUsersReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_user_endpoint.GetUsersReplay.repeatedFields_, null);
};
goog.inherits(proto.clients_user_endpoint.GetUsersReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUsersReplay.displayName = 'proto.clients_user_endpoint.GetUsersReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.ProfileSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.ProfileSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.ProfileSummaryInfo.displayName = 'proto.clients_user_endpoint.ProfileSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UserSummaryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UserSummaryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UserSummaryInfo.displayName = 'proto.clients_user_endpoint.UserSummaryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.Role.displayName = 'proto.clients_user_endpoint.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UserType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.UserType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UserType.displayName = 'proto.clients_user_endpoint.UserType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.clients_user_endpoint.Profile.repeatedFields_, null);
};
goog.inherits(proto.clients_user_endpoint.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.Profile.displayName = 'proto.clients_user_endpoint.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUserReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.GetUserReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUserReplay.displayName = 'proto.clients_user_endpoint.GetUserReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.AccountSecurityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.clients_user_endpoint.AccountSecurityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.AccountSecurityInfo.displayName = 'proto.clients_user_endpoint.AccountSecurityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.AddUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.AddUserResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.AddUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.AddUserResponse.displayName = 'proto.clients_user_endpoint.AddUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.UpdateUserResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserResponse.displayName = 'proto.clients_user_endpoint.UpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.UpdateUserActivityResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserActivityResponse.displayName = 'proto.clients_user_endpoint.UpdateUserActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.UpdateUserConfirmationStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.displayName = 'proto.clients_user_endpoint.UpdateUserConfirmationStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.GetUsersResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.GetUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUsersResponse.displayName = 'proto.clients_user_endpoint.GetUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.GetUserResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GetUserResponse.displayName = 'proto.clients_user_endpoint.GetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.ResetUserPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.ResetUserPasswordResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.ResetUserPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.ResetUserPasswordResponse.displayName = 'proto.clients_user_endpoint.ResetUserPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.UpdateAccountSecurityResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.UpdateAccountSecurityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateAccountSecurityResponse.displayName = 'proto.clients_user_endpoint.UpdateAccountSecurityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateUsernameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.UpdateUsernameResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.UpdateUsernameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateUsernameResponse.displayName = 'proto.clients_user_endpoint.UpdateUsernameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.UpdateProtectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.UpdateProtectionResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.UpdateProtectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.UpdateProtectionResponse.displayName = 'proto.clients_user_endpoint.UpdateProtectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.GenerateAccessTokenResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.GenerateAccessTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.GenerateAccessTokenResponse.displayName = 'proto.clients_user_endpoint.GenerateAccessTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.SyncInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.SyncInformationResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.SyncInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.SyncInformationResponse.displayName = 'proto.clients_user_endpoint.SyncInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.clients_user_endpoint.DeleteCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.clients_user_endpoint.DeleteCustomerResponse.oneofGroups_);
};
goog.inherits(proto.clients_user_endpoint.DeleteCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.clients_user_endpoint.DeleteCustomerResponse.displayName = 'proto.clients_user_endpoint.DeleteCustomerResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GenerateAccessTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientip: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientuseragent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessionexpirationdate: (f = msg.getSessionexpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accesstokenexpirationdate: (f = msg.getAccesstokenexpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    role: (f = msg.getRole()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GenerateAccessTokenRequest;
  return proto.clients_user_endpoint.GenerateAccessTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientuseragent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSessionexpirationdate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccesstokenexpirationdate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GenerateAccessTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientuseragent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessionexpirationdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccesstokenexpirationdate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string clientIP = 2;
 * @return {string}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getClientip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setClientip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string clientUserAgent = 3;
 * @return {string}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getClientuseragent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setClientuseragent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string FCMtoken = 4;
 * @return {string}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp sessionExpirationDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getSessionexpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setSessionexpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.clearSessionexpirationdate = function() {
  return this.setSessionexpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.hasSessionexpirationdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp accessTokenExpirationDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getAccesstokenexpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setAccesstokenexpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.clearAccesstokenexpirationdate = function() {
  return this.setAccesstokenexpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.hasAccesstokenexpirationdate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value role = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.getRole = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenRequest} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_user_endpoint.AddUserRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.AddUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.AddUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AddUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    username: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isaccountactive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isconfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    primaryemail: (f = msg.getPrimaryemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    primaryphonenumber: (f = msg.getPrimaryphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    organization: jspb.Message.getFieldWithDefault(msg, 14, 0),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    birthdate: (f = msg.getBirthdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gender: (f = msg.getGender()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    documentfileid: (f = msg.getDocumentfileid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    documentid: (f = msg.getDocumentid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isprotected: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    password: jspb.Message.getFieldWithDefault(msg, 22, ""),
    nationalitycountryiso: (f = msg.getNationalitycountryiso()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isselfsignup: jspb.Message.getBooleanFieldWithDefault(msg, 24, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.AddUserRequest}
 */
proto.clients_user_endpoint.AddUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.AddUserRequest;
  return proto.clients_user_endpoint.AddUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.AddUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.AddUserRequest}
 */
proto.clients_user_endpoint.AddUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountactive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsconfirmed(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryemail(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryphonenumber(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganization(value);
      break;
    case 15:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBirthdate(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentfileid(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentid(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsprotected(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 23:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNationalitycountryiso(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsselfsignup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.AddUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.AddUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AddUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsaccountactive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsconfirmed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPrimaryemail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryphonenumber();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getBirthdate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentfileid();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentid();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsprotected();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getNationalitycountryiso();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsselfsignup();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middleName = 2;
 * @return {string}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 4;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 5;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional int32 userType = 6;
 * @return {number}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 role = 7;
 * @return {number}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string username = 8;
 * @return {string}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool isAccountActive = 10;
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getIsaccountactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setIsaccountactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool isConfirmed = 11;
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getIsconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional google.protobuf.StringValue primaryEmail = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getPrimaryemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setPrimaryemail = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearPrimaryemail = function() {
  return this.setPrimaryemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasPrimaryemail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue primaryPhoneNumber = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getPrimaryphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setPrimaryphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearPrimaryphonenumber = function() {
  return this.setPrimaryphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasPrimaryphonenumber = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 organization = 14;
 * @return {number}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getOrganization = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional common.Address address = 15;
 * @return {?proto.common.Address}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 15));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp birthdate = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getBirthdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setBirthdate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearBirthdate = function() {
  return this.setBirthdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasBirthdate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.BoolValue gender = 18;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getGender = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 18));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setGender = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasGender = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue documentFileId = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getDocumentfileid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setDocumentfileid = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearDocumentfileid = function() {
  return this.setDocumentfileid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasDocumentfileid = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue documentId = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getDocumentid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setDocumentid = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearDocumentid = function() {
  return this.setDocumentid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasDocumentid = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional bool isProtected = 21;
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getIsprotected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setIsprotected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string password = 22;
 * @return {string}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional google.protobuf.StringValue nationalityCountryIso = 23;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getNationalitycountryiso = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 23));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
*/
proto.clients_user_endpoint.AddUserRequest.prototype.setNationalitycountryiso = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.clearNationalitycountryiso = function() {
  return this.setNationalitycountryiso(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.hasNationalitycountryiso = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool isSelfSignup = 24;
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserRequest.prototype.getIsselfsignup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.AddUserRequest} returns this
 */
proto.clients_user_endpoint.AddUserRequest.prototype.setIsselfsignup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_user_endpoint.SyncInformationRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.SyncInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.SyncInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.SyncInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    isconfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    primaryemail: (f = msg.getPrimaryemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    primaryphonenumber: (f = msg.getPrimaryphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    profileid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    birthdate: (f = msg.getBirthdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gender: (f = msg.getGender()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    documentfileid: (f = msg.getDocumentfileid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    documentid: (f = msg.getDocumentid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nationalitycountryiso: (f = msg.getNationalitycountryiso()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest}
 */
proto.clients_user_endpoint.SyncInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.SyncInformationRequest;
  return proto.clients_user_endpoint.SyncInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.SyncInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest}
 */
proto.clients_user_endpoint.SyncInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsconfirmed(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryemail(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryphonenumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 15:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBirthdate(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentfileid(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentid(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNationalitycountryiso(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.SyncInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.SyncInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.SyncInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPrimaryemail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryphonenumber();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getBirthdate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentfileid();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentid();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNationalitycountryiso();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middleName = 2;
 * @return {string}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 4;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 5;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional bool isConfirmed = 11;
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getIsconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional google.protobuf.StringValue primaryEmail = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getPrimaryemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setPrimaryemail = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearPrimaryemail = function() {
  return this.setPrimaryemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasPrimaryemail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue primaryPhoneNumber = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getPrimaryphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setPrimaryphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearPrimaryphonenumber = function() {
  return this.setPrimaryphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasPrimaryphonenumber = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string profileId = 14;
 * @return {string}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional common.Address address = 15;
 * @return {?proto.common.Address}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 15));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp birthdate = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getBirthdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setBirthdate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearBirthdate = function() {
  return this.setBirthdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasBirthdate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.BoolValue gender = 18;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getGender = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 18));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setGender = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasGender = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue documentFileId = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getDocumentfileid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setDocumentfileid = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearDocumentfileid = function() {
  return this.setDocumentfileid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasDocumentfileid = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue documentId = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getDocumentid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setDocumentid = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearDocumentid = function() {
  return this.setDocumentid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasDocumentid = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.StringValue nationalityCountryIso = 22;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.getNationalitycountryiso = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 22));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
*/
proto.clients_user_endpoint.SyncInformationRequest.prototype.setNationalitycountryiso = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationRequest} returns this
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.clearNationalitycountryiso = function() {
  return this.setNationalitycountryiso(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationRequest.prototype.hasNationalitycountryiso = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_user_endpoint.UpdateUserRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    primaryemail: (f = msg.getPrimaryemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    primaryphonenumber: (f = msg.getPrimaryphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    profileid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    birthdate: (f = msg.getBirthdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gender: (f = msg.getGender()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    documentfileid: (f = msg.getDocumentfileid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    documentid: (f = msg.getDocumentid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nationalitycountryiso: (f = msg.getNationalitycountryiso()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest}
 */
proto.clients_user_endpoint.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserRequest;
  return proto.clients_user_endpoint.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest}
 */
proto.clients_user_endpoint.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryemail(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryphonenumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 11:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBirthdate(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentfileid(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentid(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNationalitycountryiso(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPrimaryemail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryphonenumber();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getBirthdate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentfileid();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentid();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNationalitycountryiso();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middleName = 2;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 4;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 5;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional int32 userType = 6;
 * @return {number}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 role = 7;
 * @return {number}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue primaryEmail = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getPrimaryemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setPrimaryemail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearPrimaryemail = function() {
  return this.setPrimaryemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasPrimaryemail = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue primaryPhoneNumber = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getPrimaryphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setPrimaryphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearPrimaryphonenumber = function() {
  return this.setPrimaryphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasPrimaryphonenumber = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string profileId = 10;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional common.Address address = 11;
 * @return {?proto.common.Address}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 11));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp birthdate = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getBirthdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setBirthdate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearBirthdate = function() {
  return this.setBirthdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasBirthdate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.BoolValue gender = 14;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getGender = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 14));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setGender = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasGender = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue documentFileId = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getDocumentfileid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setDocumentfileid = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearDocumentfileid = function() {
  return this.setDocumentfileid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasDocumentfileid = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue documentId = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getDocumentid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setDocumentid = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearDocumentid = function() {
  return this.setDocumentid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasDocumentid = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue nationalityCountryIso = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.getNationalitycountryiso = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
*/
proto.clients_user_endpoint.UpdateUserRequest.prototype.setNationalitycountryiso = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.clearNationalitycountryiso = function() {
  return this.setNationalitycountryiso(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserRequest.prototype.hasNationalitycountryiso = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isaccountactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityRequest}
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserActivityRequest;
  return proto.clients_user_endpoint.UpdateUserActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityRequest}
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsaccountactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUserActivityRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAccountActive = 2;
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.prototype.getIsaccountactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.UpdateUserActivityRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserActivityRequest.prototype.setIsaccountactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.DeleteCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.DeleteCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.DeleteCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.DeleteCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.DeleteCustomerRequest}
 */
proto.clients_user_endpoint.DeleteCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.DeleteCustomerRequest;
  return proto.clients_user_endpoint.DeleteCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.DeleteCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.DeleteCustomerRequest}
 */
proto.clients_user_endpoint.DeleteCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.DeleteCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.DeleteCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.DeleteCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.DeleteCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.DeleteCustomerRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.DeleteCustomerRequest} returns this
 */
proto.clients_user_endpoint.DeleteCustomerRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateProtectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateProtectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateProtectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateProtectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isprotected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateProtectionRequest}
 */
proto.clients_user_endpoint.UpdateProtectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateProtectionRequest;
  return proto.clients_user_endpoint.UpdateProtectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateProtectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateProtectionRequest}
 */
proto.clients_user_endpoint.UpdateProtectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsprotected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateProtectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateProtectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateProtectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateProtectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsprotected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateProtectionRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateProtectionRequest} returns this
 */
proto.clients_user_endpoint.UpdateProtectionRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isProtected = 2;
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateProtectionRequest.prototype.getIsprotected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.UpdateProtectionRequest} returns this
 */
proto.clients_user_endpoint.UpdateProtectionRequest.prototype.setIsprotected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isaccountconfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserConfirmationStateRequest;
  return proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountconfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsaccountconfirmed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAccountConfirmed = 2;
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.prototype.getIsaccountconfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateRequest} returns this
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateRequest.prototype.setIsaccountconfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.ResetUserPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.ResetUserPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordRequest}
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.ResetUserPasswordRequest;
  return proto.clients_user_endpoint.ResetUserPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.ResetUserPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordRequest}
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.ResetUserPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.ResetUserPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.ResetUserPasswordRequest} returns this
 */
proto.clients_user_endpoint.ResetUserPasswordRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUserByUsernameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUserByUsernameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUserByUsernameRequest}
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUserByUsernameRequest;
  return proto.clients_user_endpoint.GetUserByUsernameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUserByUsernameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUserByUsernameRequest}
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUserByUsernameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUserByUsernameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.GetUserByUsernameRequest} returns this
 */
proto.clients_user_endpoint.GetUserByUsernameRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateAccountSecurityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isemailtfenabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityRequest}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateAccountSecurityRequest;
  return proto.clients_user_endpoint.UpdateAccountSecurityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityRequest}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsemailtfenabled(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateAccountSecurityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsemailtfenabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isEmailTFEnabled = 2;
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.getIsemailtfenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue TFEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} returns this
*/
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateAccountSecurityRequest.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUsernameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUsernameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUsernameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUsernameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUsernameRequest}
 */
proto.clients_user_endpoint.UpdateUsernameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUsernameRequest;
  return proto.clients_user_endpoint.UpdateUsernameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUsernameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUsernameRequest}
 */
proto.clients_user_endpoint.UpdateUsernameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUsernameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUsernameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUsernameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUsernameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUsernameRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUsernameRequest} returns this
 */
proto.clients_user_endpoint.UpdateUsernameRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.clients_user_endpoint.UpdateUsernameRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.UpdateUsernameRequest} returns this
 */
proto.clients_user_endpoint.UpdateUsernameRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    role: (f = msg.getRole()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    usertype: (f = msg.getUsertype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isprotected: (f = msg.getIsprotected()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUsersRequest}
 */
proto.clients_user_endpoint.GetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUsersRequest;
  return proto.clients_user_endpoint.GetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUsersRequest}
 */
proto.clients_user_endpoint.GetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsprotected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsprotected();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool order = 2;
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 numOfResults = 4;
 * @return {number}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value role = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getRole = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value userType = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getUsertype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.BoolValue isProtected = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.getIsprotected = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
*/
proto.clients_user_endpoint.GetUsersRequest.prototype.setIsprotected = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersRequest} returns this
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.clearIsprotected = function() {
  return this.setIsprotected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersRequest.prototype.hasIsprotected = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUserRequest}
 */
proto.clients_user_endpoint.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUserRequest;
  return proto.clients_user_endpoint.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUserRequest}
 */
proto.clients_user_endpoint.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.clients_user_endpoint.GetUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.GetUserRequest} returns this
 */
proto.clients_user_endpoint.GetUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    domain: (f = msg.getDomain()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subscription: (f = msg.getSubscription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.Organization}
 */
proto.clients_user_endpoint.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.Organization;
  return proto.clients_user_endpoint.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.Organization}
 */
proto.clients_user_endpoint.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDomain(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDomain();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.clients_user_endpoint.Organization.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.Organization} returns this
 */
proto.clients_user_endpoint.Organization.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Organization.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Organization} returns this
*/
proto.clients_user_endpoint.Organization.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Organization} returns this
 */
proto.clients_user_endpoint.Organization.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Organization.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue domain = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Organization.prototype.getDomain = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Organization} returns this
*/
proto.clients_user_endpoint.Organization.prototype.setDomain = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Organization} returns this
 */
proto.clients_user_endpoint.Organization.prototype.clearDomain = function() {
  return this.setDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Organization.prototype.hasDomain = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue subscription = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Organization.prototype.getSubscription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Organization} returns this
*/
proto.clients_user_endpoint.Organization.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Organization} returns this
 */
proto.clients_user_endpoint.Organization.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Organization.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.AccessToken.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.AccessToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.AccessToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AccessToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    refreshtoken: (f = msg.getRefreshtoken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.AccessToken}
 */
proto.clients_user_endpoint.AccessToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.AccessToken;
  return proto.clients_user_endpoint.AccessToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.AccessToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.AccessToken}
 */
proto.clients_user_endpoint.AccessToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRefreshtoken(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.AccessToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.AccessToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.AccessToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AccessToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 sessionId = 1;
 * @return {number}
 */
proto.clients_user_endpoint.AccessToken.prototype.getSessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
 */
proto.clients_user_endpoint.AccessToken.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue refreshToken = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AccessToken.prototype.getRefreshtoken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
*/
proto.clients_user_endpoint.AccessToken.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
 */
proto.clients_user_endpoint.AccessToken.prototype.clearRefreshtoken = function() {
  return this.setRefreshtoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccessToken.prototype.hasRefreshtoken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue token = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AccessToken.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
*/
proto.clients_user_endpoint.AccessToken.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
 */
proto.clients_user_endpoint.AccessToken.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccessToken.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expirationDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.AccessToken.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
*/
proto.clients_user_endpoint.AccessToken.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccessToken} returns this
 */
proto.clients_user_endpoint.AccessToken.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccessToken.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GenerateAccessTokenReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    accesstoken: (f = msg.getAccesstoken()) && proto.clients_user_endpoint.AccessToken.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && proto.clients_user_endpoint.Organization.toObject(includeInstance, f),
    passwordexpirationdate: (f = msg.getPasswordexpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    internalid: (f = msg.getInternalid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GenerateAccessTokenReply;
  return proto.clients_user_endpoint.GenerateAccessTokenReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 6:
      var value = new proto.clients_user_endpoint.AccessToken;
      reader.readMessage(value,proto.clients_user_endpoint.AccessToken.deserializeBinaryFromReader);
      msg.setAccesstoken(value);
      break;
    case 8:
      var value = new proto.clients_user_endpoint.Organization;
      reader.readMessage(value,proto.clients_user_endpoint.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPasswordexpirationdate(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInternalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GenerateAccessTokenReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccesstoken();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.clients_user_endpoint.AccessToken.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.clients_user_endpoint.Organization.serializeBinaryToWriter
    );
  }
  f = message.getPasswordexpirationdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse profile = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getProfile = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AccessToken accessToken = 6;
 * @return {?proto.clients_user_endpoint.AccessToken}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getAccesstoken = function() {
  return /** @type{?proto.clients_user_endpoint.AccessToken} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.AccessToken, 6));
};


/**
 * @param {?proto.clients_user_endpoint.AccessToken|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setAccesstoken = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearAccesstoken = function() {
  return this.setAccesstoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasAccesstoken = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Organization organization = 8;
 * @return {?proto.clients_user_endpoint.Organization}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getOrganization = function() {
  return /** @type{?proto.clients_user_endpoint.Organization} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.Organization, 8));
};


/**
 * @param {?proto.clients_user_endpoint.Organization|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp passwordExpirationDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getPasswordexpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setPasswordexpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearPasswordexpirationdate = function() {
  return this.setPasswordexpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasPasswordexpirationdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue internalId = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.getInternalid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.setInternalid = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenReply} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.clearInternalid = function() {
  return this.setInternalid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenReply.prototype.hasInternalid = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.AddUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.AddUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AddUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profileid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.AddUserReplay}
 */
proto.clients_user_endpoint.AddUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.AddUserReplay;
  return proto.clients_user_endpoint.AddUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.AddUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.AddUserReplay}
 */
proto.clients_user_endpoint.AddUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfileid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 5:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.AddUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.AddUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AddUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProfileid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string internalId = 1;
 * @return {string}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
 */
proto.clients_user_endpoint.AddUserReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 accountId = 2;
 * @return {number}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
 */
proto.clients_user_endpoint.AddUserReplay.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 profileId = 3;
 * @return {number}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.getProfileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
 */
proto.clients_user_endpoint.AddUserReplay.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
*/
proto.clients_user_endpoint.AddUserReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
 */
proto.clients_user_endpoint.AddUserReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.MessageResponse message = 5;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 5));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
*/
proto.clients_user_endpoint.AddUserReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
 */
proto.clients_user_endpoint.AddUserReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue username = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
*/
proto.clients_user_endpoint.AddUserReplay.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserReplay} returns this
 */
proto.clients_user_endpoint.AddUserReplay.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserReplay.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserReplay}
 */
proto.clients_user_endpoint.UpdateUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserReplay;
  return proto.clients_user_endpoint.UpdateUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserReplay}
 */
proto.clients_user_endpoint.UpdateUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserReplay} returns this
*/
proto.clients_user_endpoint.UpdateUserReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserReplay} returns this
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserReplay} returns this
*/
proto.clients_user_endpoint.UpdateUserReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserReplay} returns this
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.DeleteCustomerReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.DeleteCustomerReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.DeleteCustomerReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.DeleteCustomerReplay}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.DeleteCustomerReplay;
  return proto.clients_user_endpoint.DeleteCustomerReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.DeleteCustomerReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.DeleteCustomerReplay}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.DeleteCustomerReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.DeleteCustomerReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.DeleteCustomerReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.DeleteCustomerReplay} returns this
*/
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.DeleteCustomerReplay} returns this
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.DeleteCustomerReplay} returns this
*/
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.DeleteCustomerReplay} returns this
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.DeleteCustomerReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.SyncInformationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.SyncInformationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.SyncInformationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.SyncInformationReplay}
 */
proto.clients_user_endpoint.SyncInformationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.SyncInformationReplay;
  return proto.clients_user_endpoint.SyncInformationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.SyncInformationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.SyncInformationReplay}
 */
proto.clients_user_endpoint.SyncInformationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.SyncInformationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.SyncInformationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.SyncInformationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationReplay} returns this
*/
proto.clients_user_endpoint.SyncInformationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationReplay} returns this
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationReplay} returns this
*/
proto.clients_user_endpoint.SyncInformationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationReplay} returns this
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityReplay}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserActivityReplay;
  return proto.clients_user_endpoint.UpdateUserActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityReplay}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserActivityReplay} returns this
*/
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityReplay} returns this
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserActivityReplay} returns this
*/
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityReplay} returns this
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserConfirmationStateReplay;
  return proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} returns this
*/
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} returns this
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} returns this
*/
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} returns this
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateProtectionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateProtectionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateProtectionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateProtectionReplay}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateProtectionReplay;
  return proto.clients_user_endpoint.UpdateProtectionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateProtectionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateProtectionReplay}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateProtectionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateProtectionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateProtectionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateProtectionReplay} returns this
*/
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateProtectionReplay} returns this
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateProtectionReplay} returns this
*/
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateProtectionReplay} returns this
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateProtectionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.ResetUserPasswordReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.ResetUserPasswordReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    newpassword: (f = msg.getNewpassword()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.ResetUserPasswordReplay;
  return proto.clients_user_endpoint.ResetUserPasswordReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.ResetUserPasswordReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNewpassword(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.ResetUserPasswordReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.ResetUserPasswordReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNewpassword();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay} returns this
*/
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay} returns this
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue newPassword = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.getNewpassword = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay} returns this
*/
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.setNewpassword = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay} returns this
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.clearNewpassword = function() {
  return this.setNewpassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.hasNewpassword = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay} returns this
*/
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordReplay} returns this
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ResetUserPasswordReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateAccountSecurityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityReplay}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateAccountSecurityReplay;
  return proto.clients_user_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityReplay}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} returns this
*/
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} returns this
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} returns this
*/
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityReplay} returns this
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateAccountSecurityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUsernameReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUsernameReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUsernameReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUsernameReplay}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUsernameReplay;
  return proto.clients_user_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUsernameReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUsernameReplay}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUsernameReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUsernameReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUsernameReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUsernameReplay} returns this
*/
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUsernameReplay} returns this
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUsernameReplay} returns this
*/
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUsernameReplay} returns this
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUsernameReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_user_endpoint.GetUsersReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUsersReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUsersReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUsersReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUsersReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.clients_user_endpoint.UserSummaryInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUsersReplay}
 */
proto.clients_user_endpoint.GetUsersReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUsersReplay;
  return proto.clients_user_endpoint.GetUsersReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUsersReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUsersReplay}
 */
proto.clients_user_endpoint.GetUsersReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UserSummaryInfo;
      reader.readMessage(value,proto.clients_user_endpoint.UserSummaryInfo.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUsersReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUsersReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUsersReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUsersReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.clients_user_endpoint.UserSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserSummaryInfo users = 1;
 * @return {!Array<!proto.clients_user_endpoint.UserSummaryInfo>}
 */
proto.clients_user_endpoint.GetUsersReplay.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.clients_user_endpoint.UserSummaryInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.clients_user_endpoint.UserSummaryInfo, 1));
};


/**
 * @param {!Array<!proto.clients_user_endpoint.UserSummaryInfo>} value
 * @return {!proto.clients_user_endpoint.GetUsersReplay} returns this
*/
proto.clients_user_endpoint.GetUsersReplay.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.clients_user_endpoint.UserSummaryInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.clients_user_endpoint.UserSummaryInfo}
 */
proto.clients_user_endpoint.GetUsersReplay.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.clients_user_endpoint.UserSummaryInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.GetUsersReplay} returns this
 */
proto.clients_user_endpoint.GetUsersReplay.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.ProfileSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.ProfileSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ProfileSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    internalid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    usertype: (f = msg.getUsertype()) && proto.clients_user_endpoint.UserType.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.clients_user_endpoint.Role.toObject(includeInstance, f),
    isprotected: (f = msg.getIsprotected()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.ProfileSummaryInfo;
  return proto.clients_user_endpoint.ProfileSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.ProfileSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 8:
      var value = new proto.clients_user_endpoint.UserType;
      reader.readMessage(value,proto.clients_user_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 9:
      var value = new proto.clients_user_endpoint.Role;
      reader.readMessage(value,proto.clients_user_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsprotected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.ProfileSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.ProfileSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ProfileSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.clients_user_endpoint.UserType.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.clients_user_endpoint.Role.serializeBinaryToWriter
    );
  }
  f = message.getIsprotected();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse addedBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string internalId = 7;
 * @return {string}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional UserType userType = 8;
 * @return {?proto.clients_user_endpoint.UserType}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getUsertype = function() {
  return /** @type{?proto.clients_user_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UserType, 8));
};


/**
 * @param {?proto.clients_user_endpoint.UserType|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Role role = 9;
 * @return {?proto.clients_user_endpoint.Role}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getRole = function() {
  return /** @type{?proto.clients_user_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.Role, 9));
};


/**
 * @param {?proto.clients_user_endpoint.Role|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasRole = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.BoolValue isProtected = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.getIsprotected = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
*/
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.setIsprotected = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ProfileSummaryInfo} returns this
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.clearIsprotected = function() {
  return this.setIsprotected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ProfileSummaryInfo.prototype.hasIsprotected = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UserSummaryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UserSummaryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UserSummaryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.clients_user_endpoint.ProfileSummaryInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo}
 */
proto.clients_user_endpoint.UserSummaryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UserSummaryInfo;
  return proto.clients_user_endpoint.UserSummaryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UserSummaryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo}
 */
proto.clients_user_endpoint.UserSummaryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 8:
      var value = new proto.clients_user_endpoint.ProfileSummaryInfo;
      reader.readMessage(value,proto.clients_user_endpoint.ProfileSummaryInfo.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UserSummaryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UserSummaryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UserSummaryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.clients_user_endpoint.ProfileSummaryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ProfileSummaryInfo profile = 8;
 * @return {?proto.clients_user_endpoint.ProfileSummaryInfo}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.getProfile = function() {
  return /** @type{?proto.clients_user_endpoint.ProfileSummaryInfo} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.ProfileSummaryInfo, 8));
};


/**
 * @param {?proto.clients_user_endpoint.ProfileSummaryInfo|undefined} value
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
*/
proto.clients_user_endpoint.UserSummaryInfo.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserSummaryInfo} returns this
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserSummaryInfo.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.Role}
 */
proto.clients_user_endpoint.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.Role;
  return proto.clients_user_endpoint.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.Role}
 */
proto.clients_user_endpoint.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.clients_user_endpoint.Role.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.Role} returns this
 */
proto.clients_user_endpoint.Role.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Role.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Role} returns this
*/
proto.clients_user_endpoint.Role.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Role} returns this
 */
proto.clients_user_endpoint.Role.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Role.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UserType.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UserType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UserType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UserType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UserType}
 */
proto.clients_user_endpoint.UserType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UserType;
  return proto.clients_user_endpoint.UserType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UserType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UserType}
 */
proto.clients_user_endpoint.UserType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UserType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UserType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UserType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UserType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.clients_user_endpoint.UserType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.UserType} returns this
 */
proto.clients_user_endpoint.UserType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.UserType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.UserType} returns this
*/
proto.clients_user_endpoint.UserType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UserType} returns this
 */
proto.clients_user_endpoint.UserType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UserType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.clients_user_endpoint.Profile.repeatedFields_ = [7,8,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: (f = msg.getUsertype()) && proto.clients_user_endpoint.UserType.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.clients_user_endpoint.Role.toObject(includeInstance, f),
    internalid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    birthdate: (f = msg.getBirthdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gender: (f = msg.getGender()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    documentfileid: (f = msg.getDocumentfileid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    documentid: (f = msg.getDocumentid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isprotected: (f = msg.getIsprotected()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    organization: jspb.Message.getFieldWithDefault(msg, 19, 0),
    nationalitycountryiso: (f = msg.getNationalitycountryiso()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.Profile}
 */
proto.clients_user_endpoint.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.Profile;
  return proto.clients_user_endpoint.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.Profile}
 */
proto.clients_user_endpoint.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 9:
      var value = new proto.clients_user_endpoint.UserType;
      reader.readMessage(value,proto.clients_user_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 10:
      var value = new proto.clients_user_endpoint.Role;
      reader.readMessage(value,proto.clients_user_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 12:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 13:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBirthdate(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentfileid(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentid(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsprotected(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganization(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNationalitycountryiso(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.clients_user_endpoint.UserType.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.clients_user_endpoint.Role.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getBirthdate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentfileid();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentid();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsprotected();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getNationalitycountryiso();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.clients_user_endpoint.Profile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse addedBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.clients_user_endpoint.Profile.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.Profile.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 7;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.Profile.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 8;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.clients_user_endpoint.Profile.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional UserType userType = 9;
 * @return {?proto.clients_user_endpoint.UserType}
 */
proto.clients_user_endpoint.Profile.prototype.getUsertype = function() {
  return /** @type{?proto.clients_user_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UserType, 9));
};


/**
 * @param {?proto.clients_user_endpoint.UserType|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Role role = 10;
 * @return {?proto.clients_user_endpoint.Role}
 */
proto.clients_user_endpoint.Profile.prototype.getRole = function() {
  return /** @type{?proto.clients_user_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.Role, 10));
};


/**
 * @param {?proto.clients_user_endpoint.Role|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasRole = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string internalId = 11;
 * @return {string}
 */
proto.clients_user_endpoint.Profile.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated common.ExtraField extraFields = 12;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.clients_user_endpoint.Profile.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 12));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.clients_user_endpoint.Profile.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.Address address = 13;
 * @return {?proto.common.Address}
 */
proto.clients_user_endpoint.Profile.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 13));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp birthdate = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.Profile.prototype.getBirthdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setBirthdate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearBirthdate = function() {
  return this.setBirthdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasBirthdate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.BoolValue gender = 15;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.Profile.prototype.getGender = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 15));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setGender = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasGender = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue documentFileId = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.getDocumentfileid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setDocumentfileid = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearDocumentfileid = function() {
  return this.setDocumentfileid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasDocumentfileid = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue documentId = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.getDocumentid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setDocumentid = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearDocumentid = function() {
  return this.setDocumentid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasDocumentid = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.BoolValue isProtected = 18;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.Profile.prototype.getIsprotected = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 18));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setIsprotected = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearIsprotected = function() {
  return this.setIsprotected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasIsprotected = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional int32 organization = 19;
 * @return {number}
 */
proto.clients_user_endpoint.Profile.prototype.getOrganization = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional google.protobuf.StringValue nationalityCountryIso = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.Profile.prototype.getNationalitycountryiso = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.Profile} returns this
*/
proto.clients_user_endpoint.Profile.prototype.setNationalitycountryiso = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.Profile} returns this
 */
proto.clients_user_endpoint.Profile.prototype.clearNationalitycountryiso = function() {
  return this.setNationalitycountryiso(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.Profile.prototype.hasNationalitycountryiso = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUserReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUserReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accountsecurity: (f = msg.getAccountsecurity()) && proto.clients_user_endpoint.AccountSecurityInfo.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.clients_user_endpoint.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUserReplay}
 */
proto.clients_user_endpoint.GetUserReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUserReplay;
  return proto.clients_user_endpoint.GetUserReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUserReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUserReplay}
 */
proto.clients_user_endpoint.GetUserReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 8:
      var value = new proto.clients_user_endpoint.AccountSecurityInfo;
      reader.readMessage(value,proto.clients_user_endpoint.AccountSecurityInfo.deserializeBinaryFromReader);
      msg.setAccountsecurity(value);
      break;
    case 9:
      var value = new proto.clients_user_endpoint.Profile;
      reader.readMessage(value,proto.clients_user_endpoint.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUserReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUserReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccountsecurity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.clients_user_endpoint.AccountSecurityInfo.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.clients_user_endpoint.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AccountSecurityInfo accountSecurity = 8;
 * @return {?proto.clients_user_endpoint.AccountSecurityInfo}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getAccountsecurity = function() {
  return /** @type{?proto.clients_user_endpoint.AccountSecurityInfo} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.AccountSecurityInfo, 8));
};


/**
 * @param {?proto.clients_user_endpoint.AccountSecurityInfo|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setAccountsecurity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearAccountsecurity = function() {
  return this.setAccountsecurity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasAccountsecurity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Profile profile = 9;
 * @return {?proto.clients_user_endpoint.Profile}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.getProfile = function() {
  return /** @type{?proto.clients_user_endpoint.Profile} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.Profile, 9));
};


/**
 * @param {?proto.clients_user_endpoint.Profile|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
*/
proto.clients_user_endpoint.GetUserReplay.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserReplay} returns this
 */
proto.clients_user_endpoint.GetUserReplay.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserReplay.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.AccountSecurityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.AccountSecurityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AccountSecurityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isemailtfenabled: (f = msg.getIsemailtfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isphonetfenabled: (f = msg.getIsphonetfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isauthapptfenabled: (f = msg.getIsauthapptfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tfphonenumber: (f = msg.getTfphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo}
 */
proto.clients_user_endpoint.AccountSecurityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.AccountSecurityInfo;
  return proto.clients_user_endpoint.AccountSecurityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.AccountSecurityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo}
 */
proto.clients_user_endpoint.AccountSecurityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsemailtfenabled(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsphonetfenabled(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsauthapptfenabled(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.AccountSecurityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.AccountSecurityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AccountSecurityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsemailtfenabled();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsphonetfenabled();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsauthapptfenabled();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTfphonenumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue isEmailTFEnabled = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.getIsemailtfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
*/
proto.clients_user_endpoint.AccountSecurityInfo.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.clearIsemailtfenabled = function() {
  return this.setIsemailtfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.hasIsemailtfenabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isPhoneTFEnabled = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.getIsphonetfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
*/
proto.clients_user_endpoint.AccountSecurityInfo.prototype.setIsphonetfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.clearIsphonetfenabled = function() {
  return this.setIsphonetfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.hasIsphonetfenabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAuthAppTFEnabled = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.getIsauthapptfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
*/
proto.clients_user_endpoint.AccountSecurityInfo.prototype.setIsauthapptfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.clearIsauthapptfenabled = function() {
  return this.setIsauthapptfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.hasIsauthapptfenabled = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue TFEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
*/
proto.clients_user_endpoint.AccountSecurityInfo.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue TFPhoneNumber = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.getTfphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
*/
proto.clients_user_endpoint.AccountSecurityInfo.prototype.setTfphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AccountSecurityInfo} returns this
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.clearTfphonenumber = function() {
  return this.setTfphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AccountSecurityInfo.prototype.hasTfphonenumber = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.AddUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.AddUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.AddUserResponse.ResultCase}
 */
proto.clients_user_endpoint.AddUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.AddUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.AddUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.AddUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.AddUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.AddUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AddUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.AddUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.AddUserResponse}
 */
proto.clients_user_endpoint.AddUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.AddUserResponse;
  return proto.clients_user_endpoint.AddUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.AddUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.AddUserResponse}
 */
proto.clients_user_endpoint.AddUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.AddUserReplay;
      reader.readMessage(value,proto.clients_user_endpoint.AddUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.AddUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.AddUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.AddUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.AddUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.AddUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddUserReplay success = 1;
 * @return {?proto.clients_user_endpoint.AddUserReplay}
 */
proto.clients_user_endpoint.AddUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.AddUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.AddUserReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.AddUserReplay|undefined} value
 * @return {!proto.clients_user_endpoint.AddUserResponse} returns this
*/
proto.clients_user_endpoint.AddUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.AddUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.AddUserResponse} returns this
 */
proto.clients_user_endpoint.AddUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.AddUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.UpdateUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.UpdateUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.UpdateUserResponse.ResultCase}
 */
proto.clients_user_endpoint.UpdateUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.UpdateUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.UpdateUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.UpdateUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserResponse}
 */
proto.clients_user_endpoint.UpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserResponse;
  return proto.clients_user_endpoint.UpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserResponse}
 */
proto.clients_user_endpoint.UpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UpdateUserReplay;
      reader.readMessage(value,proto.clients_user_endpoint.UpdateUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.UpdateUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserReplay success = 1;
 * @return {?proto.clients_user_endpoint.UpdateUserReplay}
 */
proto.clients_user_endpoint.UpdateUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.UpdateUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UpdateUserReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.UpdateUserReplay|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserResponse} returns this
*/
proto.clients_user_endpoint.UpdateUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.UpdateUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserResponse} returns this
 */
proto.clients_user_endpoint.UpdateUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.UpdateUserActivityResponse.ResultCase}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.UpdateUserActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.UpdateUserActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.UpdateUserActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityResponse}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserActivityResponse;
  return proto.clients_user_endpoint.UpdateUserActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityResponse}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UpdateUserActivityReplay;
      reader.readMessage(value,proto.clients_user_endpoint.UpdateUserActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.UpdateUserActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserActivityReplay success = 1;
 * @return {?proto.clients_user_endpoint.UpdateUserActivityReplay}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.UpdateUserActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UpdateUserActivityReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.UpdateUserActivityReplay|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserActivityResponse} returns this
*/
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.UpdateUserActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserActivityResponse} returns this
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.ResultCase}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUserConfirmationStateResponse;
  return proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UpdateUserConfirmationStateReplay;
      reader.readMessage(value,proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.UpdateUserConfirmationStateReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUserConfirmationStateReplay success = 1;
 * @return {?proto.clients_user_endpoint.UpdateUserConfirmationStateReplay}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.UpdateUserConfirmationStateReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UpdateUserConfirmationStateReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.UpdateUserConfirmationStateReplay|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse} returns this
*/
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUserConfirmationStateResponse} returns this
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUserConfirmationStateResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.GetUsersResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.GetUsersResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.GetUsersResponse.ResultCase}
 */
proto.clients_user_endpoint.GetUsersResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.GetUsersResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.GetUsersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.GetUsersReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUsersResponse}
 */
proto.clients_user_endpoint.GetUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUsersResponse;
  return proto.clients_user_endpoint.GetUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUsersResponse}
 */
proto.clients_user_endpoint.GetUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.GetUsersReplay;
      reader.readMessage(value,proto.clients_user_endpoint.GetUsersReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.GetUsersReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetUsersReplay success = 1;
 * @return {?proto.clients_user_endpoint.GetUsersReplay}
 */
proto.clients_user_endpoint.GetUsersResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.GetUsersReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.GetUsersReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.GetUsersReplay|undefined} value
 * @return {!proto.clients_user_endpoint.GetUsersResponse} returns this
*/
proto.clients_user_endpoint.GetUsersResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.GetUsersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUsersResponse} returns this
 */
proto.clients_user_endpoint.GetUsersResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUsersResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.GetUserResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.GetUserResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.GetUserResponse.ResultCase}
 */
proto.clients_user_endpoint.GetUserResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.GetUserResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.GetUserResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.GetUserReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GetUserResponse}
 */
proto.clients_user_endpoint.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GetUserResponse;
  return proto.clients_user_endpoint.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GetUserResponse}
 */
proto.clients_user_endpoint.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.GetUserReplay;
      reader.readMessage(value,proto.clients_user_endpoint.GetUserReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.GetUserReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetUserReplay success = 1;
 * @return {?proto.clients_user_endpoint.GetUserReplay}
 */
proto.clients_user_endpoint.GetUserResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.GetUserReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.GetUserReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.GetUserReplay|undefined} value
 * @return {!proto.clients_user_endpoint.GetUserResponse} returns this
*/
proto.clients_user_endpoint.GetUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.GetUserResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GetUserResponse} returns this
 */
proto.clients_user_endpoint.GetUserResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GetUserResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.ResetUserPasswordResponse.ResultCase}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.ResetUserPasswordResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.ResetUserPasswordResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.ResetUserPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.ResetUserPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.ResetUserPasswordReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordResponse}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.ResetUserPasswordResponse;
  return proto.clients_user_endpoint.ResetUserPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.ResetUserPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordResponse}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.ResetUserPasswordReplay;
      reader.readMessage(value,proto.clients_user_endpoint.ResetUserPasswordReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.ResetUserPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.ResetUserPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.ResetUserPasswordReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResetUserPasswordReplay success = 1;
 * @return {?proto.clients_user_endpoint.ResetUserPasswordReplay}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.ResetUserPasswordReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.ResetUserPasswordReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.ResetUserPasswordReplay|undefined} value
 * @return {!proto.clients_user_endpoint.ResetUserPasswordResponse} returns this
*/
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.ResetUserPasswordResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.ResetUserPasswordResponse} returns this
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.ResetUserPasswordResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.UpdateAccountSecurityResponse.ResultCase}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.UpdateAccountSecurityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.UpdateAccountSecurityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateAccountSecurityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.UpdateAccountSecurityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityResponse}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateAccountSecurityResponse;
  return proto.clients_user_endpoint.UpdateAccountSecurityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityResponse}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UpdateAccountSecurityReplay;
      reader.readMessage(value,proto.clients_user_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateAccountSecurityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateAccountSecurityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountSecurityReplay success = 1;
 * @return {?proto.clients_user_endpoint.UpdateAccountSecurityReplay}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.UpdateAccountSecurityReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UpdateAccountSecurityReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.UpdateAccountSecurityReplay|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityResponse} returns this
*/
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.UpdateAccountSecurityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateAccountSecurityResponse} returns this
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateAccountSecurityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.UpdateUsernameResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.UpdateUsernameResponse.ResultCase}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.UpdateUsernameResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.UpdateUsernameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateUsernameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateUsernameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUsernameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.UpdateUsernameReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateUsernameResponse}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateUsernameResponse;
  return proto.clients_user_endpoint.UpdateUsernameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateUsernameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateUsernameResponse}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UpdateUsernameReplay;
      reader.readMessage(value,proto.clients_user_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateUsernameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateUsernameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateUsernameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.UpdateUsernameReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUsernameReplay success = 1;
 * @return {?proto.clients_user_endpoint.UpdateUsernameReplay}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.UpdateUsernameReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UpdateUsernameReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.UpdateUsernameReplay|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateUsernameResponse} returns this
*/
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.UpdateUsernameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateUsernameResponse} returns this
 */
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateUsernameResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.UpdateProtectionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.UpdateProtectionResponse.ResultCase}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.UpdateProtectionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.UpdateProtectionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.UpdateProtectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.UpdateProtectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateProtectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.UpdateProtectionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.UpdateProtectionResponse}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.UpdateProtectionResponse;
  return proto.clients_user_endpoint.UpdateProtectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.UpdateProtectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.UpdateProtectionResponse}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.UpdateProtectionReplay;
      reader.readMessage(value,proto.clients_user_endpoint.UpdateProtectionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.UpdateProtectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.UpdateProtectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.UpdateProtectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.UpdateProtectionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateProtectionReplay success = 1;
 * @return {?proto.clients_user_endpoint.UpdateProtectionReplay}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.UpdateProtectionReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.UpdateProtectionReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.UpdateProtectionReplay|undefined} value
 * @return {!proto.clients_user_endpoint.UpdateProtectionResponse} returns this
*/
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.UpdateProtectionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.UpdateProtectionResponse} returns this
 */
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.UpdateProtectionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.GenerateAccessTokenResponse.ResultCase}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.GenerateAccessTokenResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.GenerateAccessTokenResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.GenerateAccessTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.GenerateAccessTokenReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenResponse}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.GenerateAccessTokenResponse;
  return proto.clients_user_endpoint.GenerateAccessTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenResponse}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.GenerateAccessTokenReply;
      reader.readMessage(value,proto.clients_user_endpoint.GenerateAccessTokenReply.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.GenerateAccessTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.GenerateAccessTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.GenerateAccessTokenReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional GenerateAccessTokenReply success = 1;
 * @return {?proto.clients_user_endpoint.GenerateAccessTokenReply}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.GenerateAccessTokenReply} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.GenerateAccessTokenReply, 1));
};


/**
 * @param {?proto.clients_user_endpoint.GenerateAccessTokenReply|undefined} value
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenResponse} returns this
*/
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.GenerateAccessTokenResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.GenerateAccessTokenResponse} returns this
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.GenerateAccessTokenResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.SyncInformationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.SyncInformationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.SyncInformationResponse.ResultCase}
 */
proto.clients_user_endpoint.SyncInformationResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.SyncInformationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.SyncInformationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.SyncInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.SyncInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.SyncInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.SyncInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.SyncInformationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.SyncInformationResponse}
 */
proto.clients_user_endpoint.SyncInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.SyncInformationResponse;
  return proto.clients_user_endpoint.SyncInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.SyncInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.SyncInformationResponse}
 */
proto.clients_user_endpoint.SyncInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.SyncInformationReplay;
      reader.readMessage(value,proto.clients_user_endpoint.SyncInformationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.SyncInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.SyncInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.SyncInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.SyncInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.SyncInformationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional SyncInformationReplay success = 1;
 * @return {?proto.clients_user_endpoint.SyncInformationReplay}
 */
proto.clients_user_endpoint.SyncInformationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.SyncInformationReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.SyncInformationReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.SyncInformationReplay|undefined} value
 * @return {!proto.clients_user_endpoint.SyncInformationResponse} returns this
*/
proto.clients_user_endpoint.SyncInformationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.SyncInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.SyncInformationResponse} returns this
 */
proto.clients_user_endpoint.SyncInformationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.SyncInformationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.clients_user_endpoint.DeleteCustomerResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.clients_user_endpoint.DeleteCustomerResponse.ResultCase}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.getResultCase = function() {
  return /** @type {proto.clients_user_endpoint.DeleteCustomerResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.clients_user_endpoint.DeleteCustomerResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.clients_user_endpoint.DeleteCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.clients_user_endpoint.DeleteCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.DeleteCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.clients_user_endpoint.DeleteCustomerReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.clients_user_endpoint.DeleteCustomerResponse}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.clients_user_endpoint.DeleteCustomerResponse;
  return proto.clients_user_endpoint.DeleteCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.clients_user_endpoint.DeleteCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.clients_user_endpoint.DeleteCustomerResponse}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.clients_user_endpoint.DeleteCustomerReplay;
      reader.readMessage(value,proto.clients_user_endpoint.DeleteCustomerReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.clients_user_endpoint.DeleteCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.clients_user_endpoint.DeleteCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.clients_user_endpoint.DeleteCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.clients_user_endpoint.DeleteCustomerReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteCustomerReplay success = 1;
 * @return {?proto.clients_user_endpoint.DeleteCustomerReplay}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.getSuccess = function() {
  return /** @type{?proto.clients_user_endpoint.DeleteCustomerReplay} */ (
    jspb.Message.getWrapperField(this, proto.clients_user_endpoint.DeleteCustomerReplay, 1));
};


/**
 * @param {?proto.clients_user_endpoint.DeleteCustomerReplay|undefined} value
 * @return {!proto.clients_user_endpoint.DeleteCustomerResponse} returns this
*/
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.clients_user_endpoint.DeleteCustomerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.clients_user_endpoint.DeleteCustomerResponse} returns this
 */
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.clients_user_endpoint.DeleteCustomerResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.clients_user_endpoint);
