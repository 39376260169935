import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../../repository/UserManagement/Clients/ClientServiceClientPb';
import { GetUsersRequest, ResetUserPasswordResponse, GetUsersResponse, GetUserResponse, UpdateUserActivityResponse, UpdateUserActivityRequest, UpdateUserConfirmationStateRequest, UpdateUserConfirmationStateResponse, GetUserRequest, ResetUserPasswordRequest, UpdateProtectionResponse, UpdateProtectionRequest, DeleteCustomerResponse, DeleteCustomerRequest } from '../../../../../../repository/UserManagement/Clients/client_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../app/Api';
import { formatDate, phoneNumberFormatter, timestampToDate } from '../../../../../../app/Helpers';
import { DynamicField } from '../../../../../common/DynamicFields/DynamicFields';


export interface UserDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    isLoading: boolean
    message?: ApiMessage,
    activityFilterOption: { key: number, text: string },
    confirmationFilterOption: { key: number, text: string },
    twoFactorFilterOption: { key: number, text: string },

    newPassword: string | undefined
}

const initialState: UserDetailsPageState = {
    isChangeStateLoading: false,
    message: undefined,
    item: undefined,
    activityFilterOption: { key: 0, text: "" },
    confirmationFilterOption: { key: 0, text: "" },
    twoFactorFilterOption: { key: 0, text: "" },
    newPassword: undefined,
    isLoading: false
}

const client = new UserEndpointClient(API_URL, null, null);

export const getCustomerUser = createAsyncThunk<GetUserResponse.AsObject, APIRequest<GetUserRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/clients/userDetails/getUser',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getUser(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)
export const deleteItem = createAsyncThunk<APIResponse<DeleteCustomerResponse.AsObject>, APIRequest<DeleteCustomerRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/userDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const updateStatus = createAsyncThunk<APIResponse<UpdateUserActivityResponse.AsObject>, APIRequest<UpdateUserActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/userDetails/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsaccountactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateConfirmationStatus = createAsyncThunk<APIResponse<UpdateUserConfirmationStateResponse.AsObject>, APIRequest<UpdateUserConfirmationStateRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/userDetails/updateConfirmationStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateConfirmationState(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsaccountconfirmed() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateProtection = createAsyncThunk<APIResponse<UpdateProtectionResponse.AsObject>, APIRequest<UpdateProtectionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/userDetails/updateProtection',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateProtection(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsprotected() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const resetPassword = createAsyncThunk<APIResponse<ResetUserPasswordResponse.AsObject>, APIRequest<ResetUserPasswordRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/userDetails/resetPassword',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.resetUserPassword(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const clientsUserDetailsPageSlice = createSlice({
    name: 'pages/clients/userDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.item = initialState.item;
            state.message = initialState.message;
            state.isLoading = initialState.isLoading;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.activityFilterOption = initialState.activityFilterOption;
            state.confirmationFilterOption = initialState.confirmationFilterOption;
            state.twoFactorFilterOption = initialState.twoFactorFilterOption;
            state.newPassword = initialState.newPassword
        },
        resetCustomer: (state) => {
            state.item = initialState.item;
        },
        resetNewPassword: (state) => {
            state.newPassword = initialState.newPassword
        },

        updateUser: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        },
        updateUsername: (state, action: PayloadAction<any>) => {
            state.item.username = action.payload.username;

           
        },
        updateTwoFactor: (state, action: PayloadAction<any>) => {
            state.item.isEmailTFEnabled = action.payload.isEmailTFEnabled;
            state.item.isAuthAppTFEnabled = action.payload.isAuthAppTFEnabled;
            state.item.isPhoneTFEnabled = action.payload.isPhoneTFEnabled;
            state.item.TFEmail = action.payload.TFEmail;
            state.item.TFPhone = action.payload.TFPhone;

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerUser.fulfilled, (state, { payload }) => {
          
            if (payload) {
                let stage1 = {
                    internalId: payload?.success?.profile?.internalid ,
                    firstname: payload?.success?.profile?.firstname ? payload?.success?.profile?.firstname?.value?.trim() : "",
                    lastname: payload?.success?.profile?.lastname ? payload?.success?.profile?.lastname?.value?.trim() : "",
                    gender: payload?.success?.profile?.gender?.value,
                    nationality:  payload?.success?.profile?.nationalitycountryiso?.value ? { key: payload?.success?.profile?.nationalitycountryiso?.value, text: '' } : undefined,
                    documentId : payload?.success?.profile?.documentid ? payload?.success?.profile?.documentid?.value.trim() : "",
                    birthdate: payload?.success?.profile?.birthdate ? formatDate(timestampToDate(payload?.success?.profile?.birthdate?.seconds, payload?.success?.profile?.birthdate?.nanos), 'YYYY-MM-DD') : "",
                    phoneNumbers: [] as DynamicField[],
                    emailAddresses: [] as DynamicField[],
                    addressCountry: payload?.success?.profile?.address ? { key: payload?.success?.profile?.address?.countryiso?.value, text: '' } : { key: 'LY', text: 'Libya' },
                    addressCity: payload?.success?.profile?.address ? payload?.success?.profile?.address?.city?.value : "",
                    addressFirstLine: payload?.success?.profile?.address ? payload?.success?.profile?.address?.firstline?.value : "",
                    addressSecondLine: payload?.success?.profile?.address ? payload?.success?.profile?.address?.secondline?.value : "",
                    addressPostalCode: payload?.success?.profile?.address ? payload?.success?.profile?.address?.postalcode?.value : "",
                    isConfirmed: payload?.success?.isconfirmed?.value,
                    createdDate : payload?.success?.profile?.createddate? formatDate(timestampToDate(payload?.success?.profile?.createddate?.seconds, payload?.success?.profile?.createddate?.nanos))  : "",
                    addedBy: payload?.success?.profile?.addedby ? (payload?.success?.profile?.addedby?.firstname?.value ?? "") + " " + (payload?.success?.profile?.addedby?.lastname?.value ?? "") : "",
                    addedById: payload?.success?.profile?.addedby?.id?.value,
                    lastlogin: payload?.success?.lastlogin ? formatDate(timestampToDate(payload?.success?.lastlogin?.seconds, payload?.success?.lastlogin?.nanos)) : "",
                    username: payload?.success?.username ? payload?.success?.username?.value?.trim() : "",
                    usertype: payload?.success?.profile?.usertype ? payload?.success?.profile?.usertype.name?.value : "",
                    usertypeId: payload?.success?.profile?.usertype?.id,
                    role: payload?.success?.profile?.role ? payload?.success?.profile?.role.name?.value : "",
                    roleId: payload?.success?.profile?.role?.id,
                    isActive: payload?.success?.isactive?.value,
                    primaryEmail: (payload?.success?.accountemail) ? payload?.success?.accountemail?.value : '',
                    primaryPhoneNumber: (payload.success?.accountphonenumber) ? "+"+phoneNumberFormatter(payload.success?.accountphonenumber?.value) : '',
                    extraFields: [] as DynamicField[],
                    isEmailTFEnabled: payload?.success?.accountsecurity?.isemailtfenabled?.value,
                    isAuthAppTFEnabled: payload?.success?.accountsecurity?.isauthapptfenabled?.value,
                    isPhoneTFEnabled: payload?.success?.accountsecurity?.isphonetfenabled?.value,
                    TFEmail: payload?.success?.accountsecurity?.tfemail?.value,
                    TFPhone: payload?.success?.accountsecurity?.tfphonenumber?.value,
                    isProtected: payload?.success?.profile?.isprotected?.value,


                }
                

                  if (payload?.success?.profile?.phonenumbersList) {
                    let count = 0
                    payload?.success?.profile?.phonenumbersList?.forEach(element => {
                      stage1.phoneNumbers.push({ key: count + "", label: undefined, type: 1, value: element.value })
                      count++
                    });
                  }
          
                  if (payload?.success?.profile?.emailaddressesList) {
                    let count = 0
                    payload?.success?.profile?.emailaddressesList?.forEach(element => {
                      stage1.emailAddresses.push({ key: count + "", label: undefined, type: 1, value: element.value })
                      count++
                    });
                  }
                
          
                  
                  if (payload?.success?.profile?.extrafieldsList) {
                    let count = 0
                    payload?.success?.profile?.extrafieldsList?.forEach(element => {
                      stage1.extraFields.push({ key: element.key , label: element.label, type: element.type, value: element.value })
                      count++
                    });
                  }
               
                  state.item = stage1
                //console.log(state.items)
            }
            state.isLoading = false
        })
        builder.addCase(getCustomerUser.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getCustomerUser.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isActive = payload?.metadata.state
            }

        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateConfirmationStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isConfirmed = payload?.metadata.state
                
            }
        })
        builder.addCase(updateConfirmationStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateConfirmationStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


       
        builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.newPassword = payload?.response.success?.newpassword?.value
            }
        })
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(resetPassword.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })



        builder.addCase(updateProtection.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isProtected = payload?.metadata.state
                
            }
        })
        builder.addCase(updateProtection.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateProtection.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
        
    }
})

export const { resetNewPassword, reset, dismissMessage, resetCustomer, updateUser, updateUsername, updateTwoFactor  } = clientsUserDetailsPageSlice.actions

export default clientsUserDetailsPageSlice.reducer


