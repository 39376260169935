import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, APIResponse } from '../../../app/Api';
import { OrganizationEndpointClient } from '../../../repository/UserManagement/OrganizationServiceClientPb';
import { AddOrganizationRequest, AddOrganizationResponse, GetOrganizationsResponse, GetOrganizationsRequest, GetOrganizationRequest, GetOrganizationResponse, UpdateOrganizationRequest, UpdateOrganizationResponse, UploadLogoRequest, UploadLogoResponse } from '../../../repository/UserManagement/organization_pb';
import { TableState } from '../../common/Table/TableSate';
import { formatDate, timestampToDate } from '../../../app/Helpers';

export interface OrganizationState {
  id: number,
  name: string,
  domain: string,
  address: string,
  email: string,
  phonenumber: string,
  logo: any,
  subscription: any
}
export interface OrganizationsFormState {
  isLoading: boolean
  message?: ApiMessage
  currentOrganization?: OrganizationState
  organizations: TableState

}

const initialState: OrganizationsFormState = {
  isLoading: false,
  message: undefined,
  currentOrganization: undefined,
  organizations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new OrganizationEndpointClient(API_URL, null, null);
export const add = createAsyncThunk<AddOrganizationResponse.AsObject, APIRequest<AddOrganizationRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/organizations/add',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.add(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const getOrganizations = createAsyncThunk<APIResponse<GetOrganizationsResponse.AsObject>, APIRequest<GetOrganizationsRequest>, {
  rejectValue: ApiMessage
}>(
  'forms/organizations/getOrganizations',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.getOrganizations(req.body, req.headers ?? {});
        return { response: r.toObject(), metadata: req.body?.getSearch()?.getValue() } as APIResponse<GetOrganizationsResponse.AsObject>;

      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {
          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)




export const update = createAsyncThunk<UpdateOrganizationResponse.AsObject, APIRequest<UpdateOrganizationRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/organizations/update',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.update(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const getOrganization = createAsyncThunk<APIResponse<GetOrganizationResponse.AsObject>, APIRequest<GetOrganizationRequest>, {
  rejectValue: ApiMessage
}>(
  'forms/organizations/get',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.getOrganization(req.body, req.headers ?? {});
        return { metadata: { id: req.body.getId() }, response: r.toObject() };
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {
          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)

export const uploadLogo = createAsyncThunk<UploadLogoResponse.AsObject, APIRequest<UploadLogoRequest>, {

  rejectValue: ApiMessage
}>(
  'forms/organizations/uploadLogo',
  async (req, thunkApi) => {
    let callReq = async (): Promise<any> => {
      try {
        var r = await client.uploadLogo(req.body, req.headers ?? {});
        return r.toObject();
      } catch (err) {
        try {
          let message = toApiMessage((err as RpcError).metadata);
          if (message.data == 401) {
            var isSessionRefreshed = await refreshSession();
            if (isSessionRefreshed) {
              req.headers = {
                ...req.headers,
                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
              }
              return await callReq();
            }
          }
          return thunkApi.rejectWithValue(message)
        } catch (err) {

          return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
        }
      }
    }
    return await callReq();
  }
)




export const organizationsFormSlice = createSlice({
  name: 'forms/organizations',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCurrentOrganization: (state, action: PayloadAction<OrganizationState>) => {
      state.currentOrganization = action.payload;
    },
    dismissMessage: (state) => {
      state.message = undefined;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
      state.currentOrganization = initialState.currentOrganization;
      state.organizations = initialState.organizations;


    },
    setIsFilteredOrganizationSet: (state, action: PayloadAction<boolean>) => {
      state.organizations.isFilteredSet = action.payload;
    },

    setSelectedOrganizationItems: (state, action: PayloadAction<any[]>) => {
      state.organizations.selected = action.payload;
    },
    resetOrganizationsSet: (state) => {
      state.organizations.items = [];
      state.organizations.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(add.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload)
        state.message = toApiMessage(payload.success?.message);

    })
    builder.addCase(add.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(add.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(update.fulfilled, (state, { payload }) => {
      if (payload)
        state.message = toApiMessage(payload.success?.message);
      state.isLoading = false;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(update.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        var r = {
          id: payload.metadata?.id, domain: payload.response.success?.domain?.value ?? "",
          name: payload.response.success?.name?.value ?? "",

          address: payload.response.success?.address?.value ?? "",
          email: payload.response.success?.email?.value ?? "",
          phonenumber: payload.response.success?.phonenumber?.value ?? "",
          subscription: payload.response.success?.subscription?.value,
          logo: {} as any,

        }
        try {
          var logo = JSON.parse(payload.response.success?.logo?.value ?? "")
          r.logo = logo;
        } catch {

        }
        state.currentOrganization = r;
      }
    })
    builder.addCase(getOrganization.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(getOrganization.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })
    builder.addCase(uploadLogo.fulfilled, (state, { payload }) => {
      state.isLoading = false;

    })
    builder.addCase(uploadLogo.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(uploadLogo.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })


    builder.addCase(getOrganizations.fulfilled, (state, { payload }) => {
      state.organizations.isFetching = false
      if (payload) {
        var r = (payload?.response?.success?.organizationsList.map(val => {
          return {
            id: val.id,
            name: (val.name?.value ?? ""),
            domain: val.domain?.value ?? "",
            isActive: val.isactive,
            createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
            addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
            addedById: val.addedby?.id?.value,
          }
        }
        ) as any[])

        if (r.length < state.organizations.numberOfResults) {
          state.organizations.hasMore = false;
        }
        if (r.length == 0) {
          if (payload?.metadata != undefined && payload?.metadata != "") {
              if (!isNaN(payload?.metadata)) {
                  r = [{
                      id: payload?.metadata,
                      name: payload?.metadata,
                  }]
              }
          }
      }
        state.organizations.items = state.organizations.items.concat(r);




        //console.log(state.items)

      }
    })
    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.organizations.isFetching = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(getOrganizations.pending, (state, action) => {
      state.organizations.isFetching = true;
      state.message = undefined;
    })
  }
})

export const { reset, dismissMessage, setIsLoading, setCurrentOrganization, setSelectedOrganizationItems, setIsFilteredOrganizationSet, resetOrganizationsSet } = organizationsFormSlice.actions

export default organizationsFormSlice.reducer


