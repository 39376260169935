// source: organizationclientportalsetting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.AccountType', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.Currency', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.Limitation', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.Role', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateStatusReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateStatusRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateStatusResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.displayName = 'proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateStatusReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateStatusReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.Role.displayName = 'proto.organization_client_portal_setting_endpoint.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UserType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UserType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UserType.displayName = 'proto.organization_client_portal_setting_endpoint.UserType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.Currency.displayName = 'proto.organization_client_portal_setting_endpoint.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.AccountType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.AccountType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.AccountType.displayName = 'proto.organization_client_portal_setting_endpoint.AccountType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.Limitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.Limitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.Limitation.displayName = 'proto.organization_client_portal_setting_endpoint.Limitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.repeatedFields_, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.displayName = 'proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.displayName = 'proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateStatusRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest;
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateStatusReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.Role}
 */
proto.organization_client_portal_setting_endpoint.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.Role;
  return proto.organization_client_portal_setting_endpoint.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.Role}
 */
proto.organization_client_portal_setting_endpoint.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.Role} returns this
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.Role} returns this
*/
proto.organization_client_portal_setting_endpoint.Role.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.Role} returns this
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UserType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UserType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UserType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.UserType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UserType;
  return proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UserType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UserType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UserType} returns this
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UserType} returns this
*/
proto.organization_client_portal_setting_endpoint.UserType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UserType} returns this
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.Currency;
  return proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.Currency} returns this
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.Currency} returns this
*/
proto.organization_client_portal_setting_endpoint.Currency.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.Currency} returns this
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.AccountType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.AccountType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.AccountType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType}
 */
proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.AccountType;
  return proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.AccountType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType}
 */
proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.AccountType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.AccountType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.AccountType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType} returns this
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType} returns this
*/
proto.organization_client_portal_setting_endpoint.AccountType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType} returns this
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.Limitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.Limitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Limitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation}
 */
proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.Limitation;
  return proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.Limitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation}
 */
proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.Limitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.Limitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Limitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation} returns this
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation} returns this
*/
proto.organization_client_portal_setting_endpoint.Limitation.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation} returns this
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && proto.organization_client_portal_setting_endpoint.Role.toObject(includeInstance, f),
    usertype: (f = msg.getUsertype()) && proto.organization_client_portal_setting_endpoint.UserType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.Role;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.organization_client_portal_setting_endpoint.UserType;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.Role.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter
    );
  }
};


/**
 * optional Role role = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.Role}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.getRole = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Role, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Role|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserType userType = 2;
 * @return {?proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.getUsertype = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UserType, 2));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UserType|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: (f = msg.getCurrency()) && proto.organization_client_portal_setting_endpoint.Currency.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && proto.organization_client_portal_setting_endpoint.AccountType.toObject(includeInstance, f),
    limitation: (f = msg.getLimitation()) && proto.organization_client_portal_setting_endpoint.Limitation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.Currency;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 2:
      var value = new proto.organization_client_portal_setting_endpoint.AccountType;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 3:
      var value = new proto.organization_client_portal_setting_endpoint.Limitation;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinaryFromReader);
      msg.setLimitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.organization_client_portal_setting_endpoint.AccountType.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.organization_client_portal_setting_endpoint.Limitation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Currency currency = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getCurrency = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Currency} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Currency, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Currency|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountType accountType = 2;
 * @return {?proto.organization_client_portal_setting_endpoint.AccountType}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getAccounttype = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.AccountType} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.AccountType, 2));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.AccountType|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Limitation limitation = 3;
 * @return {?proto.organization_client_portal_setting_endpoint.Limitation}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getLimitation = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Limitation} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Limitation, 3));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Limitation|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setLimitation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearLimitation = function() {
  return this.setLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasLimitation = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    canopenaccount: (f = msg.getCanopenaccount()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    cansignup: (f = msg.getCansignup()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    privacypolicy: (f = msg.getPrivacypolicy()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    terms: (f = msg.getTerms()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    clientdefaultconfiguration: (f = msg.getClientdefaultconfiguration()) && proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.toObject(includeInstance, f),
    openaccountdefaultconfigurationsList: jspb.Message.toObjectList(msg.getOpenaccountdefaultconfigurationsList(),
    proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.toObject, includeInstance),
    support: (f = msg.getSupport()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay;
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCanopenaccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCansignup(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrivacypolicy(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTerms(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 9:
      var value = new proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinaryFromReader);
      msg.setClientdefaultconfiguration(value);
      break;
    case 10:
      var value = new proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader);
      msg.addOpenaccountdefaultconfigurations(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSupport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCanopenaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCansignup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPrivacypolicy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTerms();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClientdefaultconfiguration();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.serializeBinaryToWriter
    );
  }
  f = message.getOpenaccountdefaultconfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.serializeBinaryToWriter
    );
  }
  f = message.getSupport();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue canOpenAccount = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCanopenaccount = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCanopenaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCanopenaccount = function() {
  return this.setCanopenaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCanopenaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue canSignUp = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCansignup = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCansignup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCansignup = function() {
  return this.setCansignup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCansignup = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue privacyPolicy = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getPrivacypolicy = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setPrivacypolicy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearPrivacypolicy = function() {
  return this.setPrivacypolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasPrivacypolicy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue terms = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getTerms = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setTerms = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearTerms = function() {
  return this.setTerms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasTerms = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ClientDefaultConfigurationResponse clientDefaultConfiguration = 9;
 * @return {?proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getClientdefaultconfiguration = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse, 9));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setClientdefaultconfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearClientdefaultconfiguration = function() {
  return this.setClientdefaultconfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasClientdefaultconfiguration = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated OpenAccountDefaultConfigurationResponse openAccountDefaultConfigurations = 10;
 * @return {!Array<!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse>}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getOpenaccountdefaultconfigurationsList = function() {
  return /** @type{!Array<!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse, 10));
};


/**
 * @param {!Array<!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse>} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setOpenaccountdefaultconfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.addOpenaccountdefaultconfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearOpenaccountdefaultconfigurationsList = function() {
  return this.setOpenaccountdefaultconfigurationsList([]);
};


/**
 * optional google.protobuf.StringValue support = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getSupport = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setSupport = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearSupport = function() {
  return this.setSupport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasSupport = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateStatusResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateStatusReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateStatusReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateStatusReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateStatusReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateStatusReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateStatusReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateStatusReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateStatusResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateStatusResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse;
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrganizationClientPortalSettingReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.organization_client_portal_setting_endpoint);
