export const reCaptchaKey = '6LfXcsMgAAAAABwMTeEGi-zEeKgwjYxE5fyo3paT';

export const countriesEn =
    [
        { key: 'AF', text: 'Afghanistan' },
        { key: 'AX', text: 'Aland Islands' },
        { key: 'AL', text: 'Albania' },
        { key: 'DZ', text: 'Algeria' },
        { key: 'AS', text: 'American Samoa' },
        { key: 'AD', text: 'Andorra' },
        { key: 'AO', text: 'Angola' },
        { key: 'AI', text: 'Anguilla' },
        { key: 'AQ', text: 'Antarctica' },
        { key: 'AG', text: 'Antigua And Barbuda' },
        { key: 'AR', text: 'Argentina' },
        { key: 'AM', text: 'Armenia' },
        { key: 'AW', text: 'Aruba' },
        { key: 'AU', text: 'Australia' },
        { key: 'AT', text: 'Austria' },
        { key: 'AZ', text: 'Azerbaijan' },
        { key: 'BS', text: 'Bahamas' },
        { key: 'BH', text: 'Bahrain' },
        { key: 'BD', text: 'Bangladesh' },
        { key: 'BB', text: 'Barbados' },
        { key: 'BY', text: 'Belarus' },
        { key: 'BE', text: 'Belgium' },
        { key: 'BZ', text: 'Belize' },
        { key: 'BJ', text: 'Benin' },
        { key: 'BM', text: 'Bermuda' },
        { key: 'BT', text: 'Bhutan' },
        { key: 'BO', text: 'Bolivia' },
        { key: 'BA', text: 'Bosnia And Herzegovina' },
        { key: 'BW', text: 'Botswana' },
        { key: 'BV', text: 'Bouvet Island' },
        { key: 'BR', text: 'Brazil' },
        { key: 'IO', text: 'British Indian Ocean Territory' },
        { key: 'BN', text: 'Brunei Darussalam' },
        { key: 'BG', text: 'Bulgaria' },
        { key: 'BF', text: 'Burkina Faso' },
        { key: 'BI', text: 'Burundi' },
        { key: 'KH', text: 'Cambodia' },
        { key: 'CM', text: 'Cameroon' },
        { key: 'CA', text: 'Canada' },
        { key: 'CV', text: 'Cape Verde' },
        { key: 'KY', text: 'Cayman Islands' },
        { key: 'CF', text: 'Central African Republic' },
        { key: 'TD', text: 'Chad' },
        { key: 'CL', text: 'Chile' },
        { key: 'CN', text: 'China' },
        { key: 'CX', text: 'Christmas Island' },
        { key: 'CC', text: 'Cocos (Keeling) Islands' },
        { key: 'CO', text: 'Colombia' },
        { key: 'KM', text: 'Comoros' },
        { key: 'CG', text: 'Congo' },
        { key: 'CD', text: 'Congo, Democratic Republic' },
        { key: 'CK', text: 'Cook Islands' },
        { key: 'CR', text: 'Costa Rica' },
        { key: 'CI', text: 'Cote D"Ivoire' },
        { key: 'HR', text: 'Croatia' },
        { key: 'CU', text: 'Cuba' },
        { key: 'CY', text: 'Cyprus' },
        { key: 'CZ', text: 'Czech Republic' },
        { key: 'DK', text: 'Denmark' },
        { key: 'DJ', text: 'Djibouti' },
        { key: 'DM', text: 'Dominica' },
        { key: 'DO', text: 'Dominican Republic' },
        { key: 'EC', text: 'Ecuador' },
        { key: 'EG', text: 'Egypt' },
        { key: 'SV', text: 'El Salvador' },
        { key: 'GQ', text: 'Equatorial Guinea' },
        { key: 'ER', text: 'Eritrea' },
        { key: 'EE', text: 'Estonia' },
        { key: 'ET', text: 'Ethiopia' },
        { key: 'FK', text: 'Falkland Islands (Malvinas)' },
        { key: 'FO', text: 'Faroe Islands' },
        { key: 'FJ', text: 'Fiji' },
        { key: 'FI', text: 'Finland' },
        { key: 'FR', text: 'France' },
        { key: 'GF', text: 'French Guiana' },
        { key: 'PF', text: 'French Polynesia' },
        { key: 'TF', text: 'French Southern Territories' },
        { key: 'GA', text: 'Gabon' },
        { key: 'GM', text: 'Gambia' },
        { key: 'GE', text: 'Georgia' },
        { key: 'DE', text: 'Germany' },
        { key: 'GH', text: 'Ghana' },
        { key: 'GI', text: 'Gibraltar' },
        { key: 'GR', text: 'Greece' },
        { key: 'GL', text: 'Greenland' },
        { key: 'GD', text: 'Grenada' },
        { key: 'GP', text: 'Guadeloupe' },
        { key: 'GU', text: 'Guam' },
        { key: 'GT', text: 'Guatemala' },
        { key: 'GG', text: 'Guernsey' },
        { key: 'GN', text: 'Guinea' },
        { key: 'GW', text: 'Guinea-Bissau' },
        { key: 'GY', text: 'Guyana' },
        { key: 'HT', text: 'Haiti' },
        { key: 'HM', text: 'Heard Island & Mcdonald Islands' },
        { key: 'VA', text: 'Holy See (Vatican City State)' },
        { key: 'HN', text: 'Honduras' },
        { key: 'HK', text: 'Hong Kong' },
        { key: 'HU', text: 'Hungary' },
        { key: 'IS', text: 'Iceland' },
        { key: 'IN', text: 'India' },
        { key: 'ID', text: 'Indonesia' },
        { key: 'IR', text: 'Iran, Islamic Republic Of' },
        { key: 'IQ', text: 'Iraq' },
        { key: 'IE', text: 'Ireland' },
        { key: 'IM', text: 'Isle Of Man' },
        { key: 'IL', text: 'Israel' },
        { key: 'IT', text: 'Italy' },
        { key: 'JM', text: 'Jamaica' },
        { key: 'JP', text: 'Japan' },
        { key: 'JE', text: 'Jersey' },
        { key: 'JO', text: 'Jordan' },
        { key: 'KZ', text: 'Kazakhstan' },
        { key: 'KE', text: 'Kenya' },
        { key: 'KI', text: 'Kiribati' },
        { key: 'KR', text: 'Korea' },
        { key: 'KP', text: 'North Korea' },
        { key: 'KW', text: 'Kuwait' },
        { key: 'KG', text: 'Kyrgyzstan' },
        { key: 'LA', text: 'Lao People"s Democratic Republic' },
        { key: 'LV', text: 'Latvia' },
        { key: 'LB', text: 'Lebanon' },
        { key: 'LS', text: 'Lesotho' },
        { key: 'LR', text: 'Liberia' },
        { key: 'LY', text: 'Libya' },
        { key: 'LI', text: 'Liechtenstein' },
        { key: 'LT', text: 'Lithuania' },
        { key: 'LU', text: 'Luxembourg' },
        { key: 'MO', text: 'Macao' },
        { key: 'MK', text: 'Macedonia' },
        { key: 'MG', text: 'Madagascar' },
        { key: 'MW', text: 'Malawi' },
        { key: 'MY', text: 'Malaysia' },
        { key: 'MV', text: 'Maldives' },
        { key: 'ML', text: 'Mali' },
        { key: 'MT', text: 'Malta' },
        { key: 'MH', text: 'Marshall Islands' },
        { key: 'MQ', text: 'Martinique' },
        { key: 'MR', text: 'Mauritania' },
        { key: 'MU', text: 'Mauritius' },
        { key: 'YT', text: 'Mayotte' },
        { key: 'MX', text: 'Mexico' },
        { key: 'FM', text: 'Micronesia, Federated States Of' },
        { key: 'MD', text: 'Moldova' },
        { key: 'MC', text: 'Monaco' },
        { key: 'MN', text: 'Mongolia' },
        { key: 'ME', text: 'Montenegro' },
        { key: 'MS', text: 'Montserrat' },
        { key: 'MA', text: 'Morocco' },
        { key: 'MZ', text: 'Mozambique' },
        { key: 'MM', text: 'Myanmar' },
        { key: 'NA', text: 'Namibia' },
        { key: 'NR', text: 'Nauru' },
        { key: 'NP', text: 'Nepal' },
        { key: 'NL', text: 'Netherlands' },
        { key: 'AN', text: 'Netherlands Antilles' },
        { key: 'NC', text: 'New Caledonia' },
        { key: 'NZ', text: 'New Zealand' },
        { key: 'NI', text: 'Nicaragua' },
        { key: 'NE', text: 'Niger' },
        { key: 'NG', text: 'Nigeria' },
        { key: 'NU', text: 'Niue' },
        { key: 'NF', text: 'Norfolk Island' },
        { key: 'MP', text: 'Northern Mariana Islands' },
        { key: 'NO', text: 'Norway' },
        { key: 'OM', text: 'Oman' },
        { key: 'PK', text: 'Pakistan' },
        { key: 'PW', text: 'Palau' },
        { key: 'PS', text: 'Palestinian Territory, Occupied' },
        { key: 'PA', text: 'Panama' },
        { key: 'PG', text: 'Papua New Guinea' },
        { key: 'PY', text: 'Paraguay' },
        { key: 'PE', text: 'Peru' },
        { key: 'PH', text: 'Philippines' },
        { key: 'PN', text: 'Pitcairn' },
        { key: 'PL', text: 'Poland' },
        { key: 'PT', text: 'Portugal' },
        { key: 'PR', text: 'Puerto Rico' },
        { key: 'QA', text: 'Qatar' },
        { key: 'RE', text: 'Reunion' },
        { key: 'RO', text: 'Romania' },
        { key: 'RU', text: 'Russian Federation' },
        { key: 'RW', text: 'Rwanda' },
        { key: 'BL', text: 'Saint Barthelemy' },
        { key: 'SH', text: 'Saint Helena' },
        { key: 'KN', text: 'Saint Kitts And Nevis' },
        { key: 'LC', text: 'Saint Lucia' },
        { key: 'MF', text: 'Saint Martin' },
        { key: 'PM', text: 'Saint Pierre And Miquelon' },
        { key: 'VC', text: 'Saint Vincent And Grenadines' },
        { key: 'WS', text: 'Samoa' },
        { key: 'SM', text: 'San Marino' },
        { key: 'ST', text: 'Sao Tome And Principe' },
        { key: 'SA', text: 'Saudi Arabia' },
        { key: 'SN', text: 'Senegal' },
        { key: 'RS', text: 'Serbia' },
        { key: 'SC', text: 'Seychelles' },
        { key: 'SL', text: 'Sierra Leone' },
        { key: 'SG', text: 'Singapore' },
        { key: 'SK', text: 'Slovakia' },
        { key: 'SI', text: 'Slovenia' },
        { key: 'SB', text: 'Solomon Islands' },
        { key: 'SO', text: 'Somalia' },
        { key: 'ZA', text: 'South Africa' },
        { key: 'GS', text: 'South Georgia And Sandwich Isl.' },
        { key: 'ES', text: 'Spain' },
        { key: 'LK', text: 'Sri Lanka' },
        { key: 'SD', text: 'Sudan' },
        { key: 'SR', text: 'Suriname' },
        { key: 'SJ', text: 'Svalbard And Jan Mayen' },
        { key: 'SZ', text: 'Swaziland' },
        { key: 'SE', text: 'Sweden' },
        { key: 'CH', text: 'Switzerland' },
        { key: 'SY', text: 'Syrian Arab Republic' },
        { key: 'TW', text: 'Taiwan' },
        { key: 'TJ', text: 'Tajikistan' },
        { key: 'TZ', text: 'Tanzania' },
        { key: 'TH', text: 'Thailand' },
        { key: 'TL', text: 'Timor-Leste' },
        { key: 'TG', text: 'Togo' },
        { key: 'TK', text: 'Tokelau' },
        { key: 'TO', text: 'Tonga' },
        { key: 'TT', text: 'Trinidad And Tobago' },
        { key: 'TN', text: 'Tunisia' },
        { key: 'TR', text: 'Turkey' },
        { key: 'TM', text: 'Turkmenistan' },
        { key: 'TC', text: 'Turks And Caicos Islands' },
        { key: 'TV', text: 'Tuvalu' },
        { key: 'UG', text: 'Uganda' },
        { key: 'UA', text: 'Ukraine' },
        { key: 'AE', text: 'United Arab Emirates' },
        { key: 'GB', text: 'United Kingdom' },
        { key: 'US', text: 'United States' },
        { key: 'UM', text: 'United States Outlying Islands' },
        { key: 'UY', text: 'Uruguay' },
        { key: 'UZ', text: 'Uzbekistan' },
        { key: 'VU', text: 'Vanuatu' },
        { key: 'VE', text: 'Venezuela' },
        { key: 'VN', text: 'Vietnam' },
        { key: 'VG', text: 'Virgin Islands, British' },
        { key: 'VI', text: 'Virgin Islands, U.S.' },
        { key: 'WF', text: 'Wallis And Futuna' },
        { key: 'EH', text: 'Western Sahara' },
        { key: 'YE', text: 'Yemen' },
        { key: 'ZM', text: 'Zambia' },
        { key: 'ZW', text: 'Zimbabwe' }
    ]


export const countriesAR = [
    { key: 'AF', text: 'أفغانستان' },
    { key: 'AL', text: 'ألبانيا' },
    { key: 'DZ', text: 'الجزائر' },
    { key: 'AD', text: 'أندورا' },
    { key: 'AO', text: 'أنغولا' },
    { key: 'AG', text: 'أنتيغوا وباربودا' },
    { key: 'AR', text: 'الأرجنتين' },
    { key: 'AM', text: 'أرمينيا' },
    { key: 'AU', text: 'أستراليا' },
    { key: 'AT', text: 'النمسا' },
    { key: 'AZ', text: 'أذربيجان' },
    { key: 'BS', text: 'باهاماس' },
    { key: 'BH', text: 'البحرين' },
    { key: 'BD', text: 'بنغلاديش' },
    { key: 'BB', text: 'باربادوس' },
    { key: 'BY', text: 'بيلاروس' },
    { key: 'BE', text: 'بلجيكا' },
    { key: 'BZ', text: 'بليز' },
    { key: 'BJ', text: 'بنين' },
    { key: 'BT', text: 'بوتان' },
    { key: 'BO', text: 'بوليفيا' },
    { key: 'BA', text: 'البوسنة والهرسك' },
    { key: 'BW', text: 'بوتسوانا' },
    { key: 'BR', text: 'البرازيل' },
    { key: 'BN', text: 'بروناي' },
    { key: 'BG', text: 'بلغاريا' },
    { key: 'BF', text: 'بوركينا فاسو' },
    { key: 'BI', text: 'بوروندي' },
    { key: 'KH', text: 'كمبوديا' },
    { key: 'CM', text: 'الكاميرون' },
    { key: 'CA', text: 'كندا' },
    { key: 'CV', text: 'الرأس الأخضر' },
    { key: 'CF', text: 'جمهورية أفريقيا الوسطى' },
    { key: 'TD', text: 'تشاد' },
    { key: 'CL', text: 'تشيلي' },
    { key: 'CN', text: 'الصين' },
    { key: 'CO', text: 'كولومبيا' },
    { key: 'KM', text: 'جزر القمر' },
    { key: 'CG', text: 'جمهورية الكونغو' },
    { key: 'CD', text: 'جمهورية الكونغو الديمقراطية' },
    { key: 'CR', text: 'كوستاريكا' },
    { key: 'CI', text: 'ساحل العاج' },
    { key: 'HR', text: 'كرواتيا' },
    { key: 'CU', text: 'كوبا' },
    { key: 'CY', text: 'قبرص' },
    { key: 'CZ', text: 'جمهورية التشيك' },
    { key: 'DK', text: 'الدنمارك' },
    { key: 'DJ', text: 'جيبوتي' },
    { key: 'DM', text: 'دومينيكا' },
    { key: 'DO', text: 'جمهورية الدومينيكان' },
    { key: 'EC', text: 'الإكوادور' },
    { key: 'EG', text: 'مصر' },
    { key: 'SV', text: 'السلفادور' },
    { key: 'GQ', text: 'غينيا الاستوائية' },
    { key: 'ER', text: 'إريتريا' },
    { key: 'EE', text: 'إستونيا' },
    { key: 'ET', text: 'إثيوبيا' },
    { key: 'FJ', text: 'فيجي' },
    { key: 'FI', text: 'فنلندا' },
    { key: 'FR', text: 'فرنسا' },
    { key: 'GA', text: 'الغابون' },
    { key: 'GM', text: 'غامبيا' },
    { key: 'GE', text: 'جورجيا' },
    { key: 'DE', text: 'ألمانيا' },
    { key: 'GH', text: 'غانا' },
    { key: 'GR', text: 'اليونان' },
    { key: 'GD', text: 'غرينادا' },
    { key: 'GT', text: 'غواتيمالا' },
    { key: 'GN', text: 'غينيا' },
    { key: 'GW', text: 'غينيا بيساو' },
    { key: 'GY', text: 'غيانا' },
    { key: 'HT', text: 'هايتي' },
    { key: 'HN', text: 'هندوراس' },
    { key: 'HU', text: 'المجر' },
    { key: 'IS', text: 'آيسلندا' },
    { key: 'IN', text: 'الهند' },
    { key: 'ID', text: 'إندونيسيا' },
    { key: 'IR', text: 'إيران' },
    { key: 'IQ', text: 'العراق' },
    { key: 'IE', text: 'أيرلندا' },
    { key: 'IL', text: 'إسرائيل' },
    { key: 'IT', text: 'إيطاليا' },
    { key: 'JM', text: 'جامايكا' },
    { key: 'JP', text: 'اليابان' },
    { key: 'JO', text: 'الأردن' },
    { key: 'KZ', text: 'كازاخستان' },
    { key: 'KE', text: 'كينيا' },
    { key: 'KI', text: 'كيريباتي' },
    { key: 'KP', text: 'كوريا الشمالية' },
    { key: 'KR', text: 'كوريا الجنوبية' },
    { key: 'KW', text: 'الكويت' },
    { key: 'KG', text: 'قيرغيزستان' },
    { key: 'LA', text: 'لاوس' },
    { key: 'LV', text: 'لاتفيا' }, 
    { key: 'LB', text: 'لبنان' },
    { key: 'LS', text: 'ليسوتو' },
    { key: 'LR', text: 'ليبيريا' },
    { key: 'LY', text: 'ليبيا' },
    { key: 'LI', text: 'ليختنشتاين' },
    { key: 'LT', text: 'ليتوانيا' },
    { key: 'LU', text: 'لوكسمبورغ' },
    { key: 'MK', text: 'مقدونيا' },
    { key: 'MG', text: 'مدغشقر' },
    { key: 'MW', text: 'مالاوي' },
    { key: 'MY', text: 'ماليزيا' },
    { key: 'MV', text: 'جزر المالديف' },
    { key: 'ML', text: 'مالي' },
    { key: 'MT', text: 'مالطا' },
    { key: 'MH', text: 'جزر مارشال' },
    { key: 'MR', text: 'موريتانيا' },
    { key: 'MU', text: 'موريشيوس' },
    { key: 'MX', text: 'المكسيك' },
    { key: 'FM', text: 'ولايات ميكرونيسيا المتحدة' },
    { key: 'MA', text: 'المغرب' },
    { key: 'MD', text: 'مولدوفا' },
    { key: 'MC', text: 'موناكو' },
    { key: 'MN', text: 'منغوليا' },
    { key: 'ME', text: 'الجبل الأسود' },
    { key: 'MZ', text: 'موزمبيق' },
    { key: 'MM', text: 'ميانمار' },
    { key: 'NA', text: 'ناميبيا' },
    { key: 'NR', text: 'ناورو' },
    { key: 'NP', text: 'نيبال' },
    { key: 'NL', text: 'هولندا' },
    { key: 'NZ', text: 'نيوزيلندا' },
    { key: 'NI', text: 'نيكاراغوا' },
    { key: 'NE', text: 'النيجر' },
    { key: 'NG', text: 'نيجيريا' },
    { key: 'NO', text: 'النرويج' },
    { key: 'OM', text: 'عمان' },
    { key: 'PK', text: 'باكستان' },
    { key: 'PW', text: 'بالاو' },
    { key: 'PA', text: 'بنما' },
    { key: 'PG', text: 'بابوا غينيا الجديدة' },
    { key: 'PY', text: 'باراغواي' },
    { key: 'PE', text: 'بيرو' },
    { key: 'PH', text: 'الفلبين' },
    { key: 'PL', text: 'بولندا' },
    { key: 'PT', text: 'البرتغال' },
    { key: 'QA', text: 'قطر' },
    { key: 'RO', text: 'رومانيا' },
    { key: 'RU', text: 'روسيا' },
    { key: 'RW', text: 'رواندا' },
    { key: 'KN', text: 'سانت كيتس ونيفيس' },
    { key: 'LC', text: 'سانت لوسيا' },
    { key: 'VC', text: 'سانت فينسنت والغرينادين' },
    { key: 'WS', text: 'ساموا' },
    { key: 'SM', text: 'سان مارينو' },
    { key: 'ST', text: 'ساو تومي وبرينسيب' },
    { key: 'SA', text: 'السعودية' },
    { key: 'SN', text: 'السنغال' },
    { key: 'RS', text: 'صربيا' },
    { key: 'SC', text: 'سيشل' },
    { key: 'SL', text: 'سيراليون' },
    { key: 'SG', text: 'سنغافورة' },
    { key: 'SK', text: 'سلوفاكيا' },
    { key: 'SI', text: 'سلوفينيا' },
    { key: 'SB', text: 'جزر سليمان' },
    { key: 'SO', text: 'الصومال' },
    { key: 'ZA', text: 'جنوب أفريقيا' },
    { key: 'SS', text: 'جنوب السودان' },
    { key: 'ES', text: 'إسبانيا' },
    { key: 'LK', text: 'سريلانكا' },
    { key: 'SD', text: 'السودان' },
    { key: 'SR', text: 'سورينام' },
    { key: 'SZ', text: 'إسواتيني' },
    { key: 'SE', text: 'السويد' },
    { key: 'CH', text: 'سويسرا' },
    { key: 'SY', text: 'سوريا' },
    { key: 'TJ', text: 'طاجيكستان' },
    { key: 'TZ', text: 'تنزانيا' },
    { key: 'TH', text: 'تايلاند' },
    { key: 'TL', text: 'تيمور الشرقية' },
    { key: 'TG', text: 'توغو' },
    { key: 'TO', text: 'تونغا' },
    { key: 'TT', text: 'ترينيداد وتوباغو' },
    { key: 'TN', text: 'تونس' },
    { key: 'TR', text: 'تركيا' },
    { key: 'TM', text: 'تركمانستان' },
    { key: 'TV', text: 'توفالو' },
    { key: 'UG', text: 'أوغندا' },
    { key: 'UA', text: 'أوكرانيا' },
    { key: 'AE', text: 'الإمارات العربية المتحدة' },
    { key: 'GB', text: 'المملكة المتحدة' },
    { key: 'US', text: 'الولايات المتحدة' },
    { key: 'UY', text: 'الأوروغواي' },
    { key: 'UZ', text: 'أوزبكستان' },
    { key: 'VU', text: 'فانواتو' },
    { key: 'VE', text: 'فنزويلا' },
    { key: 'VN', text: 'فيتنام' },
    { key: 'YE', text: 'اليمن' },
    { key: 'ZM', text: 'زامبيا' },
    { key: 'ZW', text: 'زيمبابوي' },
]


export const nationalitiesAR =[
    {
      "key": "SA",
      "text": "سعودي"
    },
    {
      "key": "BH",
      "text": "بحريني"
    },
    {
      "key": "KW",
      "text": "كويتي"
    },
    {
      "key": "OM",
      "text": "عماني"
    },
    {
      "key": "QA",
      "text": "قطري"
    },
    {
      "key": "AE",
      "text": "إماراتي"
    },
    {
      "key": "DZ",
      "text": "جزائري"
    },
    {
      "key": "KM",
      "text": "جزر القمر"
    },
    {
      "key": "DJ",
      "text": "جيبوتي"
    },
    {
      "key": "EG",
      "text": "مصري"
    },
    {
      "key": "IQ",
      "text": "عراقي"
    },
    {
      "key": "JO",
      "text": "أردني"
    },
    {
      "key": "LB",
      "text": "لبناني"
    },
    {
      "key": "LY",
      "text": "ليبي"
    },
    {
      "key": "MR",
      "text": "مورتاني"
    },
    {
      "key": "MA",
      "text": "مغربي"
    },
    {
      "key": "PS",
      "text": "فلسطيني"
    },
    {
      "key": "SO",
      "text": "صومالى"
    },
    {
      "key": "SD",
      "text": "سوداني"
    },
    {
      "key": "SY",
      "text": "سوري"
    },
    {
      "key": "TN",
      "text": "تونسي"
    },
    {
      "key": "YE",
      "text": "يمني"
    },
    {
      "key": "AF",
      "text": "أفغاني"
    },
    {
      "key": "AL",
      "text": "ألباني"
    },
    {
      "key": "AZ",
      "text": "أذربيجاني"
    },
    {
      "key": "BD",
      "text": "بنجلاديشي"
    },
    {
      "key": "BJ",
      "text": "بنين"
    },
    {
      "key": "BN",
      "text": "بروناي دار السلام"
    },
    {
      "key": "BF",
      "text": "بوركينافاسو"
    },
    {
      "key": "CM",
      "text": "كاميروني"
    },
    {
      "key": "TD",
      "text": "تشادي"
    },
    {
      "key": "CI",
      "text": "ساحل العاج"
    },
    {
      "key": "GA",
      "text": "غابوني"
    },
    {
      "key": "GM",
      "text": "جامبي"
    },
    {
      "key": "GN",
      "text": "غيني"
    },
    {
      "key": "GW",
      "text": "غيني بيساو"
    },
    {
      "key": "ID",
      "text": "أندونيسي"
    },
    {
      "key": "IR",
      "text": "إيراني"
    },
    {
      "key": "KZ",
      "text": "كازاخي"
    },
    {
      "key": "KG",
      "text": "قرقيزي"
    },
    {
      "key": "MY",
      "text": "ماليزي"
    },
    {
      "key": "MV",
      "text": "مالديفي"
    },
    {
      "key": "ML",
      "text": "مالي"
    },
    {
      "key": "MZ",
      "text": "مورمبيق"
    },
    {
      "key": "NE",
      "text": "نيجيري"
    },
    {
      "key": "PK",
      "text": "باكستاني"
    },
    {
      "key": "SN",
      "text": "سنغالى"
    },
    {
      "key": "SL",
      "text": "سيراليون"
    },
    {
      "key": "SR",
      "text": "سورينام"
    },
    {
      "key": "TJ",
      "text": "طاجكي"
    },
    {
      "key": "TG",
      "text": "توجو"
    },
    {
      "key": "TR",
      "text": "تركي"
    },
    {
      "key": "TM",
      "text": "تركماني"
    },
    {
      "key": "UG",
      "text": "أوغندي"
    },
    {
      "key": "UZ",
      "text": "أوزبكي"
    },
    {
      "key": "AM",
      "text": "أروميني"
    },
    {
      "key": "BT",
      "text": "بوتاني"
    },
    {
      "key": "IO",
      "text": "إقليم الميط الهندي البريطاني"
    },
    {
      "key": "KH",
      "text": "كمبودي"
    },
    {
      "key": "CN",
      "text": "صيني"
    },
    {
      "key": "CC",
      "text": "جزر كوكوس"
    },
    {
      "key": "GE",
      "text": "جورجي"
    },
    {
      "key": "HK",
      "text": "هونغ كونغ"
    },
    {
      "key": "IN",
      "text": "هندي"
    },
    {
      "key": "JP",
      "text": "ياباني"
    },
    {
      "key": "KP",
      "text": "كوري شمالي"
    },
    {
      "key": "KR",
      "text": "كوري جنوبي"
    },
    {
      "key": "LA",
      "text": "لاوسي"
    },
    {
      "key": "MO",
      "text": "مكاو"
    },
    {
      "key": "FM",
      "text": "ولايات ميكرونيسيا المتحدة"
    },
    {
      "key": "MN",
      "text": "منغولي"
    },
    {
      "key": "MM",
      "text": "بورمي"
    },
    {
      "key": "NP",
      "text": "نيبالي"
    },
    {
      "key": "SG",
      "text": "سنغافوري"
    },
    {
      "key": "LK",
      "text": "سيرلانكي"
    },
    {
      "key": "TW",
      "text": "تايواني"
    },
    {
      "key": "TH",
      "text": "تايلندي"
    },
    {
      "key": "TL",
      "text": "تيموري"
    },
    {
      "key": "VN",
      "text": "فيتنامي"
    },
    {
      "key": "AO",
      "text": "انغولي"
    },
    {
      "key": "BW",
      "text": "بتسواني"
    },
    {
      "key": "BI",
      "text": "بروندى"
    },
    {
      "key": "CV",
      "text": "الرأس الاخضر"
    },
    {
      "key": "CF",
      "text": "أفريقيا الوسطى"
    },
    {
      "key": "CG",
      "text": "كونغو برازافيل"
    },
    {
      "key": "CD",
      "text": "كونغو زائير"
    },
    {
      "key": "GQ",
      "text": "غيني الاستوائية"
    },
    {
      "key": "ER",
      "text": "ارتيري"
    },
    {
      "key": "ET",
      "text": "أثيوبي"
    },
    {
      "key": "GH",
      "text": "غاني"
    },
    {
      "key": "KE",
      "text": "كيني"
    },
    {
      "key": "LS",
      "text": "ليسوتو"
    },
    {
      "key": "LR",
      "text": "ليبيري"
    },
    {
      "key": "MG",
      "text": "مدغشقر"
    },
    {
      "key": "MW",
      "text": "ملاوي"
    },
    {
      "key": "MU",
      "text": "موريشوس"
    },
    {
      "key": "YT",
      "text": "مايوتي"
    },
    {
      "key": "NA",
      "text": "نامبي"
    },
    {
      "key": "RE",
      "text": "جزية لا ريونيون"
    },
    {
      "key": "RW",
      "text": "راوندي"
    },
    {
      "key": "SH",
      "text": "سانت هيلانه"
    },
    {
      "key": "ST",
      "text": "ساو تومس وبيرنسيب"
    },
    {
      "key": "SC",
      "text": "سيشل"
    },
    {
      "key": "ZA",
      "text": "جنوب أفريقي"
    },
    {
      "key": "SS",
      "text": "جنوب السودان"
    },
    {
      "key": "SZ",
      "text": "سوازيلاند"
    },
    {
      "key": "TZ",
      "text": "تنراني"
    },
    {
      "key": "EH",
      "text": "الصحراء الغربية"
    },
    {
      "key": "ZM",
      "text": "زامبي"
    },
    {
      "key": "ZW",
      "text": "زيمبابوي"
    },
    {
      "key": "AT",
      "text": "نمساوي"
    },
    {
      "key": "BE",
      "text": "بلجكي"
    },
    {
      "key": "BG",
      "text": "بلغاري"
    },
    {
      "key": "HR",
      "text": "كرواتي"
    },
    {
      "key": "CY",
      "text": "قبرصي"
    },
    {
      "key": "CZ",
      "text": "تشبكي"
    },
    {
      "key": "DK",
      "text": "دنماركي"
    },
    {
      "key": "EE",
      "text": "استوني"
    },
    {
      "key": "FI",
      "text": "فلندي"
    },
    {
      "key": "FR",
      "text": "فرنسي"
    },
    {
      "key": "DE",
      "text": "ألماني"
    },
    {
      "key": "GR",
      "text": "يوناني"
    },
    {
      "key": "HU",
      "text": "هنقاري"
    },
    {
      "key": "IE",
      "text": "ايرلندي"
    },
    {
      "key": "IT",
      "text": "إيطالي"
    },
    {
      "key": "LV",
      "text": "لاتفي"
    },
    {
      "key": "LT",
      "text": "لاتوني"
    },
    {
      "key": "LU",
      "text": "لوكسمبورغ"
    },
    {
      "key": "MT",
      "text": "مالطي"
    },
    {
      "key": "NL",
      "text": "هولندي"
    },
    {
      "key": "PL",
      "text": "بولندي"
    },
    {
      "key": "PT",
      "text": "برتغالي"
    },
    {
      "key": "RO",
      "text": "روماني"
    },
    {
      "key": "SK",
      "text": "سلوفاكي"
    },
    {
      "key": "SI",
      "text": "سلوفاني"
    },
    {
      "key": "ES",
      "text": "أسباني"
    },
    {
      "key": "SE",
      "text": "سويدي"
    },
    {
      "key": "GB",
      "text": "برطاني"
    },
    {
      "key": "AX",
      "text": "جزر أولاند"
    },
    {
      "key": "AD",
      "text": "اندورا"
    },
    {
      "key": "BY",
      "text": "بيلوروسي"
    },
    {
      "key": "FO",
      "text": "جزر فارو"
    },
    {
      "key": "GI",
      "text": "جبل طارق"
    },
    {
      "key": "GG",
      "text": "جيرنسي"
    },
    {
      "key": "VA",
      "text": "الكرسي الرولى"
    },
    {
      "key": "IS",
      "text": "أيسلند"
    },
    {
      "key": "IM",
      "text": "جزيرة مان"
    },
    {
      "key": "JE",
      "text": "جيرزي"
    },
    {
      "key": "LI",
      "text": "ليختنشتاين"
    },
    {
      "key": "MK",
      "text": "مقدوني"
    },
    {
      "key": "MD",
      "text": "مولدافي"
    },
    {
      "key": "MC",
      "text": "موناكو"
    },
    {
      "key": "ME",
      "text": "الجبل الأسود"
    },
    {
      "key": "NO",
      "text": "نرويجي"
    },
    {
      "key": "RU",
      "text": "روسي"
    },
    {
      "key": "SM",
      "text": "سان مارينو"
    },
    {
      "key": "RS",
      "text": "صربي"
    },
    {
      "key": "SJ",
      "text": "سفالبارد جان ماين"
    },
    {
      "key": "CH",
      "text": "سويسري"
    },
    {
      "key": "UA",
      "text": "اوكراني"
    },
    {
      "key": "AI",
      "text": "أنغويلا"
    },
    {
      "key": "AG",
      "text": "أنتيغوا وبربودا"
    },
    {
      "key": "BS",
      "text": "باهامس"
    },
    {
      "key": "BB",
      "text": "باربادوس"
    },
    {
      "key": "BZ",
      "text": "بليز"
    },
    {
      "key": "BM",
      "text": "برمواد"
    },
    {
      "key": "CA",
      "text": "كندي"
    },
    {
      "key": "KY",
      "text": "جزر الكمايمان"
    },
    {
      "key": "CR",
      "text": "كوستاريكي"
    },
    {
      "key": "CU",
      "text": "كوبي"
    },
    {
      "key": "DM",
      "text": "دومينيكا"
    },
    {
      "key": "DO",
      "text": "دومينيكي"
    },
    {
      "key": "SV",
      "text": "سلفادوري"
    },
    {
      "key": "GL",
      "text": "جرينلاند"
    },
    {
      "key": "GD",
      "text": "غرينادا"
    },
    {
      "key": "GP",
      "text": "غوادلوب"
    },
    {
      "key": "GT",
      "text": "غواتيمالي"
    },
    {
      "key": "HT",
      "text": "هايتي"
    },
    {
      "key": "HN",
      "text": "هندوراسي"
    },
    {
      "key": "JM",
      "text": "جامايكي"
    },
    {
      "key": "MQ",
      "text": "مارتينيك"
    },
    {
      "key": "MX",
      "text": "مكسيكي"
    },
    {
      "key": "MS",
      "text": "مونترات"
    },
    {
      "key": "NI",
      "text": "نيكاراغوي"
    },
    {
      "key": "PA",
      "text": "بنمي"
    },
    {
      "key": "PR",
      "text": "بورتوريكي"
    },
    {
      "key": "BL",
      "text": "سان يارتيلمي"
    },
    {
      "key": "KN",
      "text": "سانت كيس ونفيس"
    },
    {
      "key": "MF",
      "text": "سانت مارتن الجزء الفرنسي"
    },
    {
      "key": "PM",
      "text": "سان بيير وميكلون"
    },
    {
      "key": "VC",
      "text": "سانت فنسينت والجرينادينز"
    },
    {
      "key": "SX",
      "text": "سينت مارتن الجزء الهولندي"
    },
    {
      "key": "TT",
      "text": "تريندا وتوباجو"
    },
    {
      "key": "TC",
      "text": "جزر توركس وكايكوس"
    },
    {
      "key": "UM",
      "text": "جزر الولايات المتحدة الصغيرة النائية"
    },
    {
      "key": "US",
      "text": "أمريكي"
    },
    {
      "key": "VG",
      "text": "جرو العذراء البرطانية"
    },
    {
      "key": "VI",
      "text": "جرو العذراء الأمريكية"
    },
    {
      "key": "AR",
      "text": "أرجنتيني"
    },
    {
      "key": "AW",
      "text": "أوربا"
    },
    {
      "key": "BO",
      "text": "بوليفي"
    },
    {
      "key": "BR",
      "text": "برازيلي"
    },
    {
      "key": "CL",
      "text": "تشيلي"
    },
    {
      "key": "CO",
      "text": "كولومبي"
    },
    {
      "key": "CW",
      "text": "كوراساو"
    },
    {
      "key": "EC",
      "text": "اكوادوري"
    },
    {
      "key": "FK",
      "text": "جزرر فوكلاند"
    },
    {
      "key": "GF",
      "text": "غويانا الفرنسية"
    },
    {
      "key": "GY",
      "text": "غيانا"
    },
    {
      "key": "PY",
      "text": "باراغواي"
    },
    {
      "key": "PE",
      "text": "بيرو"
    },
    {
      "key": "GS",
      "text": "جورجيا"
    },
    {
      "key": "UY",
      "text": "اوراغواي"
    },
    {
      "key": "VE",
      "text": "فنزويلي"
    },
    {
      "key": "AS",
      "text": "ساموا الأمريكية"
    },
    {
      "key": "AU",
      "text": "أسترالى"
    },
    {
      "key": "CX",
      "text": "جريرة الكريسماس"
    },
    {
      "key": "CK",
      "text": "جزر كوك"
    },
    {
      "key": "FJ",
      "text": "فيجي"
    },
    {
      "key": "PF",
      "text": "بولينزيا الفرنسية"
    },
    {
      "key": "TF",
      "text": "الأقاليم الجنوبية الفرنسية"
    },
    {
      "key": "GU",
      "text": "غوام"
    },
    {
      "key": "KI",
      "text": "كيريباتي"
    },
    {
      "key": "MH",
      "text": "جزر مارشال"
    },
    {
      "key": "NR",
      "text": "ناورو"
    },
    {
      "key": "NC",
      "text": "نيوكالدوني"
    },
    {
      "key": "NU",
      "text": "نييوي"
    },
    {
      "key": "PW",
      "text": "بالاو"
    },
    {
      "key": "PG",
      "text": "بايوا غينيا الجديدة"
    },
    {
      "key": "PN",
      "text": "جزر بيتكرين"
    },
    {
      "key": "WS",
      "text": "ساموا"
    },
    {
      "key": "SB",
      "text": "جزيرة سليمان"
    },
    {
      "key": "TK",
      "text": "توكيلاو"
    },
    {
      "key": "TO",
      "text": "تونجا"
    },
    {
      "key": "TV",
      "text": "توفالو"
    },
    {
      "key": "VU",
      "text": "فانواتو"
    },
    {
      "key": "WF",
      "text": "واليس وفوتونا"
    }
  ]

export const nationalitiesEn = [
    {
      "key": "AF",
      "text": "Afghan"
    },
    {
      "key": "AX",
      "text": "Åland Island"
    },
    {
      "key": "AL",
      "text": "Albanian"
    },
    {
      "key": "DZ",
      "text": "Algerian"
    },
    {
      "key": "AS",
      "text": "American Samoan"
    },
    {
      "key": "AD",
      "text": "Andorran"
    },
    {
      "key": "AO",
      "text": "Angolan"
    },
    {
      "key": "AI",
      "text": "Anguillan"
    },
    {
      "key": "AQ",
      "text": "Antarctic"
    },
    {
      "key": "AG",
      "text": "Antiguan or Barbudan"
    },
    {
      "key": "AR",
      "text": "Argentine"
    },
    {
      "key": "AM",
      "text": "Armenian"
    },
    {
      "key": "AW",
      "text": "Aruban"
    },
    {
      "key": "AU",
      "text": "Australian"
    },
    {
      "key": "AT",
      "text": "Austrian"
    },
    {
      "key": "AZ",
      "text": "Azerbaijani, Azeri"
    },
    {
      "key": "BS",
      "text": "Bahamian"
    },
    {
      "key": "BH",
      "text": "Bahraini"
    },
    {
      "key": "BD",
      "text": "Bangladeshi"
    },
    {
      "key": "BB",
      "text": "Barbadian"
    },
    {
      "key": "BY",
      "text": "Belarusian"
    },
    {
      "key": "BE",
      "text": "Belgian"
    },
    {
      "key": "BZ",
      "text": "Belizean"
    },
    {
      "key": "BJ",
      "text": "Beninese, Beninois"
    },
    {
      "key": "BM",
      "text": "Bermudian, Bermudan"
    },
    {
      "key": "BT",
      "text": "Bhutanese"
    },
    {
      "key": "BO",
      "text": "Bolivian"
    },
    {
      "key": "BQ",
      "text": "Bonaire"
    },
    {
      "key": "BA",
      "text": "Bosnian or Herzegovinian"
    },
    {
      "key": "BW",
      "text": "Motswana, Botswanan"
    },
    {
      "key": "BV",
      "text": "Bouvet Island"
    },
    {
      "key": "BR",
      "text": "Brazilian"
    },
    {
      "key": "IO",
      "text": "BIOT"
    },
    {
      "key": "BN",
      "text": "Bruneian"
    },
    {
      "key": "BG",
      "text": "Bulgarian"
    },
    {
      "key": "BF",
      "text": "Burkinabé"
    },
    {
      "key": "BI",
      "text": "Burundian"
    },
    {
      "key": "CV",
      "text": "Cabo Verdean"
    },
    {
      "key": "KH",
      "text": "Cambodian"
    },
    {
      "key": "CM",
      "text": "Cameroonian"
    },
    {
      "key": "CA",
      "text": "Canadian"
    },
    {
      "key": "KY",
      "text": "Caymanian"
    },
    {
      "key": "CF",
      "text": "Central African"
    },
    {
      "key": "TD",
      "text": "Chadian"
    },
    {
      "key": "CL",
      "text": "Chilean"
    },
    {
      "key": "CN",
      "text": "Chinese"
    },
    {
      "key": "CX",
      "text": "Christmas Island"
    },
    {
      "key": "CC",
      "text": "Cocos Island"
    },
    {
      "key": "CO",
      "text": "Colombian"
    },
    {
      "key": "KM",
      "text": "Comoran, Comorian"
    },
    {
      "key": "CG",
      "text": "Congolese"
    },
    {
      "key": "CD",
      "text": "Congolese"
    },
    {
      "key": "CK",
      "text": "Cook Island"
    },
    {
      "key": "CR",
      "text": "Costa Rican"
    },
    {
      "key": "CI",
      "text": "Ivorian"
    },
    {
      "key": "HR",
      "text": "Croatian"
    },
    {
      "key": "CU",
      "text": "Cuban"
    },
    {
      "key": "CW",
      "text": "Curaçaoan"
    },
    {
      "key": "CY",
      "text": "Cypriot"
    },
    {
      "key": "CZ",
      "text": "Czech"
    },
    {
      "key": "DK",
      "text": "Danish"
    },
    {
      "key": "DJ",
      "text": "Djiboutian"
    },
    {
      "key": "DM",
      "text": "Dominican"
    },
    {
      "key": "DO",
      "text": "Dominican"
    },
    {
      "key": "EC",
      "text": "Ecuadorian"
    },
    {
      "key": "EG",
      "text": "Egyptian"
    },
    {
      "key": "SV",
      "text": "Salvadoran"
    },
    {
      "key": "GQ",
      "text": "Equatorial Guinean, Equatoguinean"
    },
    {
      "key": "ER",
      "text": "Eritrean"
    },
    {
      "key": "EE",
      "text": "Estonian"
    },
    {
      "key": "ET",
      "text": "Ethiopian"
    },
    {
      "key": "FK",
      "text": "Falkland Island"
    },
    {
      "key": "FO",
      "text": "Faroese"
    },
    {
      "key": "FJ",
      "text": "Fijian"
    },
    {
      "key": "FI",
      "text": "Finnish"
    },
    {
      "key": "FR",
      "text": "French"
    },
    {
      "key": "GF",
      "text": "French Guianese"
    },
    {
      "key": "PF",
      "text": "French Polynesian"
    },
    {
      "key": "TF",
      "text": "French Southern Territories"
    },
    {
      "key": "GA",
      "text": "Gabonese"
    },
    {
      "key": "GM",
      "text": "Gambian"
    },
    {
      "key": "GE",
      "text": "Georgian"
    },
    {
      "key": "DE",
      "text": "German"
    },
    {
      "key": "GH",
      "text": "Ghanaian"
    },
    {
      "key": "GI",
      "text": "Gibraltar"
    },
    {
      "key": "GR",
      "text": "Greek, Hellenic"
    },
    {
      "key": "GL",
      "text": "Greenlandic"
    },
    {
      "key": "GD",
      "text": "Grenadian"
    },
    {
      "key": "GP",
      "text": "Guadeloupe"
    },
    {
      "key": "GU",
      "text": "Guamanian, Guambat"
    },
    {
      "key": "GT",
      "text": "Guatemalan"
    },
    {
      "key": "GG",
      "text": "Channel Island"
    },
    {
      "key": "GN",
      "text": "Guinean"
    },
    {
      "key": "GW",
      "text": "Bissau-Guinean"
    },
    {
      "key": "GY",
      "text": "Guyanese"
    },
    {
      "key": "HT",
      "text": "Haitian"
    },
    {
      "key": "HM",
      "text": "Heard Island or McDonald Islands"
    },
    {
      "key": "VA",
      "text": "Vatican"
    },
    {
      "key": "HN",
      "text": "Honduran"
    },
    {
      "key": "HK",
      "text": "Hong Kong, Hong Kongese"
    },
    {
      "key": "HU",
      "text": "Hungarian, Magyar"
    },
    {
      "key": "IS",
      "text": "Icelandic"
    },
    {
      "key": "IN",
      "text": "Indian"
    },
    {
      "key": "ID",
      "text": "Indonesian"
    },
    {
      "key": "IR",
      "text": "Iranian, Persian"
    },
    {
      "key": "IQ",
      "text": "Iraqi"
    },
    {
      "key": "IE",
      "text": "Irish"
    },
    {
      "key": "IM",
      "text": "Manx"
    },
    {
      "key": "IL",
      "text": "Israeli"
    },
    {
      "key": "IT",
      "text": "Italian"
    },
    {
      "key": "JM",
      "text": "Jamaican"
    },
    {
      "key": "JP",
      "text": "Japanese"
    },
    {
      "key": "JE",
      "text": "Channel Island"
    },
    {
      "key": "JO",
      "text": "Jordanian"
    },
    {
      "key": "KZ",
      "text": "Kazakhstani, Kazakh"
    },
    {
      "key": "KE",
      "text": "Kenyan"
    },
    {
      "key": "KI",
      "text": "I-Kiribati"
    },
    {
      "key": "KP",
      "text": "North Korean"
    },
    {
      "key": "KR",
      "text": "South Korean"
    },
    {
      "key": "KW",
      "text": "Kuwaiti"
    },
    {
      "key": "KG",
      "text": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
    },
    {
      "key": "LA",
      "text": "Lao, Laotian"
    },
    {
      "key": "LV",
      "text": "Latvian"
    },
    {
      "key": "LB",
      "text": "Lebanese"
    },
    {
      "key": "LS",
      "text": "Basotho"
    },
    {
      "key": "LR",
      "text": "Liberian"
    },
    {
      "key": "LY",
      "text": "Libyan"
    },
    {
      "key": "LI",
      "text": "Liechtenstein"
    },
    {
      "key": "LT",
      "text": "Lithuanian"
    },
    {
      "key": "LU",
      "text": "Luxembourg, Luxembourgish"
    },
    {
      "key": "MO",
      "text": "Macanese, Chinese"
    },
    {
      "key": "MK",
      "text": "Macedonian"
    },
    {
      "key": "MG",
      "text": "Malagasy"
    },
    {
      "key": "MW",
      "text": "Malawian"
    },
    {
      "key": "MY",
      "text": "Malaysian"
    },
    {
      "key": "MV",
      "text": "Maldivian"
    },
    {
      "key": "ML",
      "text": "Malian, Malinese"
    },
    {
      "key": "MT",
      "text": "Maltese"
    },
    {
      "key": "MH",
      "text": "Marshallese"
    },
    {
      "key": "MQ",
      "text": "Martiniquais, Martinican"
    },
    {
      "key": "MR",
      "text": "Mauritanian"
    },
    {
      "key": "MU",
      "text": "Mauritian"
    },
    {
      "key": "YT",
      "text": "Mahoran"
    },
    {
      "key": "MX",
      "text": "Mexican"
    },
    {
      "key": "FM",
      "text": "Micronesian"
    },
    {
      "key": "MD",
      "text": "Moldovan"
    },
    {
      "key": "MC",
      "text": "Monégasque, Monacan"
    },
    {
      "key": "MN",
      "text": "Mongolian"
    },
    {
      "key": "ME",
      "text": "Montenegrin"
    },
    {
      "key": "MS",
      "text": "Montserratian"
    },
    {
      "key": "MA",
      "text": "Moroccan"
    },
    {
      "key": "MZ",
      "text": "Mozambican"
    },
    {
      "key": "MM",
      "text": "Burmese"
    },
    {
      "key": "NA",
      "text": "Namibian"
    },
    {
      "key": "NR",
      "text": "Nauruan"
    },
    {
      "key": "NP",
      "text": "Nepali, Nepalese"
    },
    {
      "key": "NL",
      "text": "Dutch, Netherlandic"
    },
    {
      "key": "NC",
      "text": "New Caledonian"
    },
    {
      "key": "NZ",
      "text": "New Zealand, NZ"
    },
    {
      "key": "NI",
      "text": "Nicaraguan"
    },
    {
      "key": "NE",
      "text": "Nigerien"
    },
    {
      "key": "NG",
      "text": "Nigerian"
    },
    {
      "key": "NU",
      "text": "Niuean"
    },
    {
      "key": "NF",
      "text": "Norfolk Island"
    },
    {
      "key": "MP",
      "text": "Northern Marianan"
    },
    {
      "key": "NO",
      "text": "Norwegian"
    },
    {
      "key": "OM",
      "text": "Omani"
    },
    {
      "key": "PK",
      "text": "Pakistani"
    },
    {
      "key": "PW",
      "text": "Palauan"
    },
    {
      "key": "PS",
      "text": "Palestinian"
    },
    {
      "key": "PA",
      "text": "Panamanian"
    },
    {
      "key": "PG",
      "text": "Papua New Guinean, Papuan"
    },
    {
      "key": "PY",
      "text": "Paraguayan"
    },
    {
      "key": "PE",
      "text": "Peruvian"
    },
    {
      "key": "PH",
      "text": "Philippine, Filipino"
    },
    {
      "key": "PN",
      "text": "Pitcairn Island"
    },
    {
      "key": "PL",
      "text": "Polish"
    },
    {
      "key": "PT",
      "text": "Portuguese"
    },
    {
      "key": "PR",
      "text": "Puerto Rican"
    },
    {
      "key": "QA",
      "text": "Qatari"
    },
    {
      "key": "RE",
      "text": "Réunionese, Réunionnais"
    },
    {
      "key": "RO",
      "text": "Romanian"
    },
    {
      "key": "RU",
      "text": "Russian"
    },
    {
      "key": "RW",
      "text": "Rwandan"
    },
    {
      "key": "BL",
      "text": "Barthélemois"
    },
    {
      "key": "SH",
      "text": "Saint Helenian"
    },
    {
      "key": "KN",
      "text": "Kittitian or Nevisian"
    },
    {
      "key": "LC",
      "text": "Saint Lucian"
    },
    {
      "key": "MF",
      "text": "Saint-Martinoise"
    },
    {
      "key": "PM",
      "text": "Saint-Pierrais or Miquelonnais"
    },
    {
      "key": "VC",
      "text": "Saint Vincentian, Vincentian"
    },
    {
      "key": "WS",
      "text": "Samoan"
    },
    {
      "key": "SM",
      "text": "Sammarinese"
    },
    {
      "key": "ST",
      "text": "São Toméan"
    },
    {
      "key": "SA",
      "text": "Saudi, Saudi Arabian"
    },
    {
      "key": "SN",
      "text": "Senegalese"
    },
    {
      "key": "RS",
      "text": "Serbian"
    },
    {
      "key": "SC",
      "text": "Seychellois"
    },
    {
      "key": "SL",
      "text": "Sierra Leonean"
    },
    {
      "key": "SG",
      "text": "Singaporean"
    },
    {
      "key": "SX",
      "text": "Sint Maarten"
    },
    {
      "key": "SK",
      "text": "Slovak"
    },
    {
      "key": "SI",
      "text": "Slovenian, Slovene"
    },
    {
      "key": "SB",
      "text": "Solomon Island"
    },
    {
      "key": "SO",
      "text": "Somali, Somalian"
    },
    {
      "key": "ZA",
      "text": "South African"
    },
    {
      "key": "GS",
      "text": "South Georgia or South Sandwich Islands"
    },
    {
      "key": "SS",
      "text": "South Sudanese"
    },
    {
      "key": "ES",
      "text": "Spanish"
    },
    {
      "key": "LK",
      "text": "Sri Lankan"
    },
    {
      "key": "SD",
      "text": "Sudanese"
    },
    {
      "key": "SR",
      "text": "Surinamese"
    },
    {
      "key": "SJ",
      "text": "Svalbard"
    },
    {
      "key": "SZ",
      "text": "Swazi"
    },
    {
      "key": "SE",
      "text": "Swedish"
    },
    {
      "key": "CH",
      "text": "Swiss"
    },
    {
      "key": "SY",
      "text": "Syrian"
    },
    {
      "key": "TW",
      "text": "Chinese, Taiwanese"
    },
    {
      "key": "TJ",
      "text": "Tajikistani"
    },
    {
      "key": "TZ",
      "text": "Tanzanian"
    },
    {
      "key": "TH",
      "text": "Thai"
    },
    {
      "key": "TL",
      "text": "Timorese"
    },
    {
      "key": "TG",
      "text": "Togolese"
    },
    {
      "key": "TK",
      "text": "Tokelauan"
    },
    {
      "key": "TO",
      "text": "Tongan"
    },
    {
      "key": "TT",
      "text": "Trinidadian or Tobagonian"
    },
    {
      "key": "TN",
      "text": "Tunisian"
    },
    {
      "key": "TR",
      "text": "Turkish"
    },
    {
      "key": "TM",
      "text": "Turkmen"
    },
    {
      "key": "TC",
      "text": "Turks and Caicos Island"
    },
    {
      "key": "TV",
      "text": "Tuvaluan"
    },
    {
      "key": "UG",
      "text": "Ugandan"
    },
    {
      "key": "UA",
      "text": "Ukrainian"
    },
    {
      "key": "AE",
      "text": "Emirati, Emirian, Emiri"
    },
    {
      "key": "GB",
      "text": "British, UK"
    },
    {
      "key": "UM",
      "text": "American"
    },
    {
      "key": "US",
      "text": "American"
    },
    {
      "key": "UY",
      "text": "Uruguayan"
    },
    {
      "key": "UZ",
      "text": "Uzbekistani, Uzbek"
    },
    {
      "key": "VU",
      "text": "Ni-Vanuatu, Vanuatuan"
    },
    {
      "key": "VE",
      "text": "Venezuelan"
    },
    {
      "key": "VN",
      "text": "Vietnamese"
    },
    {
      "key": "VG",
      "text": "British Virgin Island"
    },
    {
      "key": "VI",
      "text": "U.S. Virgin Island"
    },
    {
      "key": "WF",
      "text": "Wallis and Futuna, Wallisian or Futunan"
    },
    {
      "key": "EH",
      "text": "Sahrawi, Sahrawian, Sahraouian"
    },
    {
      "key": "YE",
      "text": "Yemeni"
    },
    {
      "key": "ZM",
      "text": "Zambian"
    },
    {
      "key": "ZW",
      "text": "Zimbabwean"
    }
  ]