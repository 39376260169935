import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../repository/UserManagement/Clients/ClientServiceClientPb';
import { GetUsersRequest, ResetUserPasswordResponse, GetUsersResponse, GetUserResponse, UpdateUserActivityResponse, UpdateUserActivityRequest, UpdateUserConfirmationStateRequest, UpdateUserConfirmationStateResponse, GetUserRequest, ResetUserPasswordRequest, UpdateProtectionResponse, UpdateProtectionRequest, DeleteCustomerResponse, DeleteCustomerRequest } from '../../../../../repository/UserManagement/Clients/client_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';

import { TableState } from '../../../../common/Table/TableSate';
import { GetRolesRequest, GetRolesResponse , GetUserTypesRequest, GetUserTypesResponse} from '../../../../../repository/UserManagement/organization_pb';
import { number } from 'yup';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { OrganizationEndpointClient } from '../../../../../repository/UserManagement/OrganizationServiceClientPb';


export interface UsersPageState {
    customers: TableState
    roles: TableState
    userTypes: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    activityFilterOption: { key: number, text: string },
    confirmationFilterOption: { key: number, text: string },
    twoFactorFilterOption: { key: number, text: string },
    protectionFilterOption: { key: number, text: string },
    filters: any[],
    newPassword: string | undefined
}

const initialState: UsersPageState = {
    customers: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    roles: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    userTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    activityFilterOption: { key: 0, text: "" },
    confirmationFilterOption: { key: 0, text: "" },
    twoFactorFilterOption: { key: 0, text: "" },
    protectionFilterOption: { key: 0, text: "" },
    filters: [],
    newPassword: undefined
}

const client = new UserEndpointClient(API_URL, null, null);
const clientOrg = new OrganizationEndpointClient(API_URL, null, null);

export const getCustomerUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/clients/users/getUsers',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getUsers(req.body, req.headers ?? {});
                //console.log(r.toObject())
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateStatus = createAsyncThunk<APIResponse<UpdateUserActivityResponse.AsObject>, APIRequest<UpdateUserActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/users/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsaccountactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const updateProtection = createAsyncThunk<APIResponse<UpdateProtectionResponse.AsObject>, APIRequest<UpdateProtectionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/users/updateProtection',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateProtection(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid(), state: req.body.getIsprotected() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)
export const deleteUser = createAsyncThunk<APIResponse<DeleteCustomerResponse.AsObject>, APIRequest<DeleteCustomerRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/clients/users/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getProfileid() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



const getFilters = (state: UsersPageState): any[] => {

    const selected = [];
   
    if (state.activityFilterOption.key != 0) {
        selected.push({ key: 3, name: state.activityFilterOption.text });
    }
    if (state.confirmationFilterOption.key != 0) {
        selected.push({ key: 4, name: state.confirmationFilterOption.text });
    }
    if (state.twoFactorFilterOption.key != 0) {
        selected.push({ key: 5, name: state.twoFactorFilterOption.text });
    }
    if (state.protectionFilterOption.key != 0) {
        selected.push({ key: 6, name: state.protectionFilterOption.text });
    }
    return selected;
}

export const clientsUsersPageSlice = createSlice({
    name: 'pages/clients/users',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.customers = initialState.customers;
            state.message = initialState.message;
            state.roles = initialState.roles;
            state.userTypes = initialState.userTypes;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.activityFilterOption = initialState.activityFilterOption;
            state.confirmationFilterOption = initialState.confirmationFilterOption;
            state.protectionFilterOption = initialState.protectionFilterOption;
            state.twoFactorFilterOption = initialState.twoFactorFilterOption;
            state.filters = initialState.filters
            state.newPassword = initialState.newPassword
        },
        resetCustomers: (state) => {
            state.customers.items = [];
            state.customers.hasMore = true;
        },
        resetNewPassword: (state) => {
            state.newPassword = initialState.newPassword
        },
        resetRoles: (state) => {
            state.roles.items = [];
            state.roles.hasMore = true;
        },
        resetUserTypes: (state) => {
            state.userTypes.items = [];
            state.userTypes.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.customers.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.customers.numberOfResults = action.payload;
        },
        setSelectedCustomer: (state, action: PayloadAction<any[]>) => {
            state.customers.selected = action.payload;
        },
        setSelectedRole: (state, action: PayloadAction<any[]>) => {
            state.roles.selected = action.payload;
            state.filters = getFilters(state);
        },
        setSelectedUserType: (state, action: PayloadAction<any[]>) => {
            state.userTypes.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;

        },
        setActivityFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.activityFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setConfirmationFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.confirmationFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setProtectionFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.protectionFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setTwoFactorFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.twoFactorFilterOption = action.payload;
            state.filters = getFilters(state);

        },
        setIsFilteredCustomersSet: (state, action: PayloadAction<boolean>) => {
            state.customers.isFilteredSet = action.payload;
        },
        setIsFilteredRolesSet: (state, action: PayloadAction<boolean>) => {
            state.roles.isFilteredSet = action.payload;
        },
        setIsFilteredUserTypesSet: (state, action: PayloadAction<boolean>) => {
            state.userTypes.isFilteredSet = action.payload;
        },

        addUser: (state, action: PayloadAction<any>) => {
            if (!state.customers.isFilteredSet) {
                if (!state.customers.hasMore && !state.customers.isDescending) {
                    state.customers.items.push(action.payload);
                } else if (state.customers.isDescending) {
                    state.customers.items.unshift(action.payload);
                }
            }
        },
        updateUser: (state, action: PayloadAction<any>) => {
            let selected = state.customers.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).internalId == action?.payload?.internalId) {
                    selected[0] = action?.payload
                }
                var u = state.customers.items.findIndex(e => e.internalId == action?.payload?.internalId)
                if (u >= 0) {
                    state.customers.items[u] = action?.payload
                }
            }
        },
        updateUsername: (state, action: PayloadAction<any>) => {
            let selected = state.customers.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).internalId == action?.payload?.internalId) {
                    selected[0].username = action?.payload?.username
                }
                var u = state.customers.items.findIndex(e => e.internalId == action?.payload?.internalId)
                if (u >= 0) {
                    state.customers.items[u].username = action?.payload?.username
                }
            }
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerUsers.fulfilled, (state, { payload }) => {
            state.customers.isFetching = false
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                     return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive?.value,
                        isConfirmed: val.isconfirmed?.value,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value ?? "",
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value ?? "",
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                        isProtected: val.profile?.isprotected?.value,

                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.customers.items.length != 0) {
                    l = state.customers.items.slice(0, state.customers.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.customers.numberOfResults) {
                    state.customers.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.customers.items = l
                //console.log(state.items)

            } else {
                state.customers.hasMore = false;
            }
        })
        builder.addCase(getCustomerUsers.rejected, (state, action) => {
            state.customers.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getCustomerUsers.pending, (state, action) => {
            state.customers.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.customers.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        state.customers.selected = []
                    }
                    var u = state.customers.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.customers.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteUser.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.customers.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.customers.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.customers.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateProtection.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.customers.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).isProtected = payload?.metadata.state
                    }
                    var u = state.customers.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.customers.items.at(u).isProtected = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateProtection.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateProtection.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })



    
   
    }
})

export const {resetNewPassword, updateUsername, setProtectionFilterOption ,setIsFilteredCustomersSet, setIsFilteredRolesSet, setIsFilteredUserTypesSet, reset, dismissMessage, setSort, setNumberOfResults, resetRoles, resetCustomers, setSelectedCustomer, resetUserTypes, setSelectedRole, setSelectedUserType, setSearchText, setActivityFilterOption, setConfirmationFilterOption, setTwoFactorFilterOption, addUser, updateUser } = clientsUsersPageSlice.actions

export default clientsUsersPageSlice.reducer


