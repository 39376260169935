/**
 * @fileoverview gRPC-Web generated client stub for clients_user_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as client_pb from './client_pb';


export class UserEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    client_pb.AddUserRequest,
    client_pb.AddUserResponse,
    (request: client_pb.AddUserRequest) => {
      return request.serializeBinary();
    },
    client_pb.AddUserResponse.deserializeBinary
  );

  add(
    request: client_pb.AddUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.AddUserResponse>;

  add(
    request: client_pb.AddUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.AddUserResponse) => void): grpcWeb.ClientReadableStream<client_pb.AddUserResponse>;

  add(
    request: client_pb.AddUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.AddUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateUserRequest,
    client_pb.UpdateUserResponse,
    (request: client_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateUserResponse.deserializeBinary
  );

  update(
    request: client_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.UpdateUserResponse>;

  update(
    request: client_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateUserResponse>;

  update(
    request: client_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateUserActivityRequest,
    client_pb.UpdateUserActivityResponse,
    (request: client_pb.UpdateUserActivityRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateUserActivityResponse.deserializeBinary
  );

  updateActivity(
    request: client_pb.UpdateUserActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.UpdateUserActivityResponse>;

  updateActivity(
    request: client_pb.UpdateUserActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUserActivityResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateUserActivityResponse>;

  updateActivity(
    request: client_pb.UpdateUserActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUserActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorUpdateConfirmationState = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/UpdateConfirmationState',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateUserConfirmationStateRequest,
    client_pb.UpdateUserConfirmationStateResponse,
    (request: client_pb.UpdateUserConfirmationStateRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateUserConfirmationStateResponse.deserializeBinary
  );

  updateConfirmationState(
    request: client_pb.UpdateUserConfirmationStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.UpdateUserConfirmationStateResponse>;

  updateConfirmationState(
    request: client_pb.UpdateUserConfirmationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUserConfirmationStateResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateUserConfirmationStateResponse>;

  updateConfirmationState(
    request: client_pb.UpdateUserConfirmationStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUserConfirmationStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/UpdateConfirmationState',
        request,
        metadata || {},
        this.methodDescriptorUpdateConfirmationState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/UpdateConfirmationState',
    request,
    metadata || {},
    this.methodDescriptorUpdateConfirmationState);
  }

  methodDescriptorUpdateAccountSecurity = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/UpdateAccountSecurity',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateAccountSecurityRequest,
    client_pb.UpdateAccountSecurityResponse,
    (request: client_pb.UpdateAccountSecurityRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateAccountSecurityResponse.deserializeBinary
  );

  updateAccountSecurity(
    request: client_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.UpdateAccountSecurityResponse>;

  updateAccountSecurity(
    request: client_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateAccountSecurityResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateAccountSecurityResponse>;

  updateAccountSecurity(
    request: client_pb.UpdateAccountSecurityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateAccountSecurityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/UpdateAccountSecurity',
        request,
        metadata || {},
        this.methodDescriptorUpdateAccountSecurity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/UpdateAccountSecurity',
    request,
    metadata || {},
    this.methodDescriptorUpdateAccountSecurity);
  }

  methodDescriptorUpdateUsername = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/UpdateUsername',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateUsernameRequest,
    client_pb.UpdateUsernameResponse,
    (request: client_pb.UpdateUsernameRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateUsernameResponse.deserializeBinary
  );

  updateUsername(
    request: client_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.UpdateUsernameResponse>;

  updateUsername(
    request: client_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUsernameResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateUsernameResponse>;

  updateUsername(
    request: client_pb.UpdateUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateUsernameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/UpdateUsername',
        request,
        metadata || {},
        this.methodDescriptorUpdateUsername,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/UpdateUsername',
    request,
    metadata || {},
    this.methodDescriptorUpdateUsername);
  }

  methodDescriptorUpdateProtection = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/UpdateProtection',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateProtectionRequest,
    client_pb.UpdateProtectionResponse,
    (request: client_pb.UpdateProtectionRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateProtectionResponse.deserializeBinary
  );

  updateProtection(
    request: client_pb.UpdateProtectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.UpdateProtectionResponse>;

  updateProtection(
    request: client_pb.UpdateProtectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateProtectionResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateProtectionResponse>;

  updateProtection(
    request: client_pb.UpdateProtectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateProtectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/UpdateProtection',
        request,
        metadata || {},
        this.methodDescriptorUpdateProtection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/UpdateProtection',
    request,
    metadata || {},
    this.methodDescriptorUpdateProtection);
  }

  methodDescriptorGetUsers = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/GetUsers',
    grpcWeb.MethodType.UNARY,
    client_pb.GetUsersRequest,
    client_pb.GetUsersResponse,
    (request: client_pb.GetUsersRequest) => {
      return request.serializeBinary();
    },
    client_pb.GetUsersResponse.deserializeBinary
  );

  getUsers(
    request: client_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.GetUsersResponse>;

  getUsers(
    request: client_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.GetUsersResponse) => void): grpcWeb.ClientReadableStream<client_pb.GetUsersResponse>;

  getUsers(
    request: client_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.GetUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/GetUsers',
        request,
        metadata || {},
        this.methodDescriptorGetUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/GetUsers',
    request,
    metadata || {},
    this.methodDescriptorGetUsers);
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/GetUser',
    grpcWeb.MethodType.UNARY,
    client_pb.GetUserRequest,
    client_pb.GetUserResponse,
    (request: client_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    client_pb.GetUserResponse.deserializeBinary
  );

  getUser(
    request: client_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.GetUserResponse>;

  getUser(
    request: client_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<client_pb.GetUserResponse>;

  getUser(
    request: client_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorGetUserByUsername = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/GetUserByUsername',
    grpcWeb.MethodType.UNARY,
    client_pb.GetUserByUsernameRequest,
    client_pb.GetUserResponse,
    (request: client_pb.GetUserByUsernameRequest) => {
      return request.serializeBinary();
    },
    client_pb.GetUserResponse.deserializeBinary
  );

  getUserByUsername(
    request: client_pb.GetUserByUsernameRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.GetUserResponse>;

  getUserByUsername(
    request: client_pb.GetUserByUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<client_pb.GetUserResponse>;

  getUserByUsername(
    request: client_pb.GetUserByUsernameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/GetUserByUsername',
        request,
        metadata || {},
        this.methodDescriptorGetUserByUsername,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/GetUserByUsername',
    request,
    metadata || {},
    this.methodDescriptorGetUserByUsername);
  }

  methodDescriptorResetUserPassword = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/ResetUserPassword',
    grpcWeb.MethodType.UNARY,
    client_pb.ResetUserPasswordRequest,
    client_pb.ResetUserPasswordResponse,
    (request: client_pb.ResetUserPasswordRequest) => {
      return request.serializeBinary();
    },
    client_pb.ResetUserPasswordResponse.deserializeBinary
  );

  resetUserPassword(
    request: client_pb.ResetUserPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.ResetUserPasswordResponse>;

  resetUserPassword(
    request: client_pb.ResetUserPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.ResetUserPasswordResponse) => void): grpcWeb.ClientReadableStream<client_pb.ResetUserPasswordResponse>;

  resetUserPassword(
    request: client_pb.ResetUserPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.ResetUserPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/ResetUserPassword',
        request,
        metadata || {},
        this.methodDescriptorResetUserPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/ResetUserPassword',
    request,
    metadata || {},
    this.methodDescriptorResetUserPassword);
  }

  methodDescriptorGenerateAccessToken = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/GenerateAccessToken',
    grpcWeb.MethodType.UNARY,
    client_pb.GenerateAccessTokenRequest,
    client_pb.GenerateAccessTokenResponse,
    (request: client_pb.GenerateAccessTokenRequest) => {
      return request.serializeBinary();
    },
    client_pb.GenerateAccessTokenResponse.deserializeBinary
  );

  generateAccessToken(
    request: client_pb.GenerateAccessTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.GenerateAccessTokenResponse>;

  generateAccessToken(
    request: client_pb.GenerateAccessTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.GenerateAccessTokenResponse) => void): grpcWeb.ClientReadableStream<client_pb.GenerateAccessTokenResponse>;

  generateAccessToken(
    request: client_pb.GenerateAccessTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.GenerateAccessTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/GenerateAccessToken',
        request,
        metadata || {},
        this.methodDescriptorGenerateAccessToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/GenerateAccessToken',
    request,
    metadata || {},
    this.methodDescriptorGenerateAccessToken);
  }

  methodDescriptorSyncInformation = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/SyncInformation',
    grpcWeb.MethodType.UNARY,
    client_pb.SyncInformationRequest,
    client_pb.SyncInformationResponse,
    (request: client_pb.SyncInformationRequest) => {
      return request.serializeBinary();
    },
    client_pb.SyncInformationResponse.deserializeBinary
  );

  syncInformation(
    request: client_pb.SyncInformationRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.SyncInformationResponse>;

  syncInformation(
    request: client_pb.SyncInformationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.SyncInformationResponse) => void): grpcWeb.ClientReadableStream<client_pb.SyncInformationResponse>;

  syncInformation(
    request: client_pb.SyncInformationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.SyncInformationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/SyncInformation',
        request,
        metadata || {},
        this.methodDescriptorSyncInformation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/SyncInformation',
    request,
    metadata || {},
    this.methodDescriptorSyncInformation);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/clients_user_endpoint.UserEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    client_pb.DeleteCustomerRequest,
    client_pb.DeleteCustomerResponse,
    (request: client_pb.DeleteCustomerRequest) => {
      return request.serializeBinary();
    },
    client_pb.DeleteCustomerResponse.deserializeBinary
  );

  delete(
    request: client_pb.DeleteCustomerRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_pb.DeleteCustomerResponse>;

  delete(
    request: client_pb.DeleteCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.DeleteCustomerResponse) => void): grpcWeb.ClientReadableStream<client_pb.DeleteCustomerResponse>;

  delete(
    request: client_pb.DeleteCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.DeleteCustomerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/clients_user_endpoint.UserEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/clients_user_endpoint.UserEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

}

